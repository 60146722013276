import { GET_CURRENT_USER, LOAD_CURRENT_USER, SET_CURRENT_USER, POPULATE_CURRENT_USER } from '../actions/types'

const initialState = {
    item : null,
    isLoading: false,
    isSaved: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_CURRENT_USER:
            return {
                ...state,
                item: null,
                isLoading: action.payload,
                isSaved: false
            }
            break;
        case GET_CURRENT_USER:
            return {
                ...state,
                item: action.payload,
                isLoading: false,
                isSaved: false
            }
            break;
        case SET_CURRENT_USER:
            return {
                ...state,
                item: action.payload,
                isLoading: false,
                isSaved: true
            }
            break;
        case POPULATE_CURRENT_USER:
            return {
                ...state,
                item: action.payload,
                isLoading: false
            }
            break;
    
        default:
            return state
    }
}