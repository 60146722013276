import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { searchCompanies, selectCompany } from '../../../actions/companyActions'
import { checkPromoToken } from '../../../actions/promoTokenActions'
import { populateUserFromCompanyData } from '../../../actions/userActions'
import { ScaleLoader } from 'react-spinners';

class SearchCompany extends Component {

  constructor(props) {
    super(props)
    this.state={
      search : ''
    }

    this.changeInput = this.changeInput.bind(this)
    this.selectCompany = this.selectCompany.bind(this)
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })

    clearTimeout(this.timer);
    this.timer = setTimeout( () => {
      this.props.searchCompanies(this.state.search)
    }, 1000)
  }

  componentDidMount() {
    if(this.props.match.params.token) {
      var promoToken = this.props.match.params.token
      
      this.props.checkPromoToken(promoToken)
    }
  }

  selectCompany(c){
    console.log(this.props.promoToken)
    if(this.props.promoToken.token) {
      c.promoToken = this.props.promoToken.token
    }
    this.props.selectCompany(c)
  }

  render() {
    const styles = {
      companyBox: {
        width: 250,
        border: "1px solid #BB3234",
        textAlign: "left",
        padding: 10,
        margin: "auto",
        marginBottom: 5,
        fontSize: 14,
        cursor: "pointer"
      },
      companySelected: {
        backgroundColor: "#0D66A8",
        border: "1px solid #0D66A8",
        color: "white"
      }
    }

    const {companies, errors, promoToken} = this.props
    const companiesList = []
    if(!companies.selected && companies.items){
      companies.items && companies.items.forEach((elem, k) => {
        companiesList.push(
          <div key={k}
            style={styles.companyBox}
            onClick={() => this.selectCompany( elem)}
            key={k+'_'+elem.siren}>
                <div>{elem.l1_declaree}</div> 
                <div>{elem.siret}</div>
                <div>{elem.codpos} {elem.libcom}</div>
          </div>
        )
      })
    } 

    var companySelected = null
    if(companies.selected){
      companySelected = (
        <div>
          <div style={{... styles.companyBox, ...styles.companySelected}}>
            <div>{companies.selected.l1_declaree}</div> 
            <div>{companies.selected.siret}</div>
            <div>{companies.selected.codpos} {companies.selected.libcom}</div>
          </div>
          <Link to="/inscription/2" className="btn btn-lqo btn-lqo-primary mb-3">SELECTIONNER</Link>
        </div>
      )
    }


    return (
      <div className="animated fadeIn">
        <h1>Créer mon compte utilisateur — étape 1 sur 2</h1>
        <div className="m-auto">
        {promoToken.isLoading && <div className="text-center"><ScaleLoader color={'#bb3234'} /> Vérification du code promo</div>}
        
          <div className="form-group row">
            <label htmlFor="search" className="col-3 col-form-label">Société</label>
            <div className="col-7">
              <input type="text" className="form-control" id="search" name="search" placeholder="Ex: Le Quotidien 974"
                onChange={this.changeInput} value={this.state.search} />
              <small className="form-text text-muted">Entrez le nom de votre société, son SIREN ou sa ville, etc…</small>
              <small className="form-text text-muted">Ex : Le Quotidien 974</small>
            </div>
          </div>

          {companies.isLoading && <div className="text-center"><ScaleLoader color={'#bb3234'} /></div>}

          <div className="text-center">{companiesList}</div>

          {!companies.isLoading && companies.items && companiesList.length == 0 && !companies.selected && (
            <div className="text-muted text-center">Aucun résultat trouvé, essayez une recherche différente</div>
          )}

          {companies.items && !companies.selected && (
            <div className="text-center">
              <Link to="/inscription/2">Je ne trouve pas ma société</Link>
            </div>
          )}
          <div className="text-center">{companySelected}</div>
          
        </div>
      </div>
    );
  }
}

SearchCompany.propTypes = {
  searchCompanies: PropTypes.func.isRequired,
  selectCompany: PropTypes.func.isRequired,
  populateUserFromCompanyData: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  checkPromoToken : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  companies: state.companies,
  errors: state.errors,
  promoToken: state.promoToken
})

export default connect(mapStateToProps, { searchCompanies, selectCompany, populateUserFromCompanyData, checkPromoToken })(withRouter(SearchCompany));