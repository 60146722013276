import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_CONTRACTS} from './types'
import { api_url } from '../config/config'

// Create user
export const createContract = (data) => dispatch => {

    return new Promise((resolve, reject) => {
        dispatch({type: CLEAR_ERRORS, payload: {}})
        dispatch({type: LOAD_CONTRACTS, payload: true})
        
        axios.post(api_url + 'contracts', data)
            .then(res => {
                dispatch({type: LOAD_CONTRACTS, payload: false})
                resolve(res.data)
            })
            .catch(err => {
                dispatch({type: LOAD_CONTRACTS, payload: false})
                dispatch({type: GET_ERRORS, payload: err.response.data})
                reject(err.response.data)
            })
    })
}
