import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getLatestPublicMarkets} from '../../actions/publicMarketActions'
import { ScaleLoader } from 'react-spinners'

class LastMarket extends Component {

    componentDidMount()
    {
        this.props.getLatestPublicMarkets()
    }

    render() {
        const {publicMarkets} = this.props
        var latest = []
        publicMarkets.latest.forEach((a, k) => {
            latest.push(
                <p key={a.id}>
                    <span className="text-blue-alt font-weight-bold">{k + 1} - {a.title} : </span>
                    <span dangerouslySetInnerHTML={{ __html: a.content}}></span>
                </p>
            )
        })

        return (
            <div className="contentBox">
                <h2 className="lastLegal">derniers marchés publics</h2>
                <div className="p-3">{latest}</div>
                <div className="text-right">
                    {this.props.viewPublicMarket}
                </div>
                {publicMarkets.latest && publicMarkets.isLoading && <ScaleLoader className="text-center" color={"#BB3234"} />}
            </div>
        )
    }
}

LastMarket.propTypes = {
  publicMarkets: PropTypes.object.isRequired,
  getLatestPublicMarkets: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    publicMarkets: state.publicMarkets
})

export default connect(mapStateToProps, {getLatestPublicMarkets})(LastMarket);