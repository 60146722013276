import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_ALERTS, GET_ALERTS, GET_ALERT, ADD_ALERT, DELETE_ALERT, UPDATE_ALERT} from './types'
import { api_url } from '../config/config'

// Add alert
export const addAlert = (postData) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ALERTS, payload: true})
    
    axios.post(api_url + 'alerts', postData)
        .then(res => {
            dispatch({type: ADD_ALERT, payload: res.data})
            window.location.href='/mes-alertes'
        })
        .catch(err => {
            dispatch({type: LOAD_ALERTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Update alert
export const updateAlert = (postData) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ALERTS, payload: true})
    
    axios.patch(api_url + 'alerts/'+postData.id, postData)
        .then(res => {
            dispatch({type: UPDATE_ALERT, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ALERTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Get alerts
export const getAlerts = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ALERTS, payload: true})
    
    axios.get(api_url + 'alerts')
        .then(res => {
            dispatch({type: GET_ALERTS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ALERTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Get alert
export const getAlert = (postData) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ALERTS, payload: true})
    
    axios.get(api_url + 'alerts/'+postData)
        .then(res => {
            dispatch({type: GET_ALERT, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ALERTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Delete alert
export const deleteAlert = (postData) => dispatch => {
    
    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ALERTS, payload: true})
    console.log('action')
    axios.delete(api_url + 'alerts/'+postData)
        .then(res => {
            dispatch({type: DELETE_ALERT, payload: postData})
        })
        .catch(err => {
            dispatch({type: LOAD_ALERTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}



