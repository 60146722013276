import React, { Component } from 'react'


class AdFooter extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    
    return (
      <div className="col-12 col-md-8 col-lg-9 py-4 text-center">
        <iframe id='aea20993' name='aea20993' src='https://servedby.revive-adserver.net/afr.php?n=aea20993&amp;zoneid=504&amp;cb=16503589569783006027' frameBorder='0' scrolling='no' width='728' height='90'>
          <a href='https://servedby.revive-adserver.net/ck.php?n=aa23ccc2&amp;cb=16503589569783006027' target='_blank'>
            <img src='https://servedby.revive-adserver.net/avw.php?zoneid=504&amp;cb=16503589569783006027&amp;n=aa23ccc2' border='0' alt='' />
          </a>
        </iframe>
        <script type='text/javascript' src='https://servedby.revive-adserver.net/ag.php'></script>
      </div>
    )
  }
}



export default AdFooter