import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getPublicMarket } from '../../../actions/publicMarketActions'
import { ScaleLoader } from 'react-spinners'
import { DateTime } from 'luxon'
import { api_url, image_url } from '../../../config/config'
import storageHelper from '../../../utils/storageHelper'

class PublicMarket extends Component {

  constructor(props) {
    super(props)

    this.getPublicMarketPDF = this.getPublicMarketPDF.bind(this)
  }

  getPublicMarketPDF(id)
  {
    window.open(api_url + 'pdf/publicmarkets/' + id + '/' + localStorage.getItem('jwtToken').replace('Bearer ', ''))
  }

  componentDidMount()
  {
    this.props.getPublicMarket(this.props.match.params.id)
  }

  render() {
    const {constants} = this.props
    const publicMarket = this.props.publicMarkets.item

    let isAvisDAttribution = false
    if(publicMarket && publicMarket.type == 6){
      isAvisDAttribution = true
    }
    let cat = []
    if(publicMarket && constants.items){
      constants.items.categoriesMP.forEach((c) => {
        if(publicMarket.categories.find(x => c.id.toString() == x)){
          cat.push(c.name)
        }
      })
    }
    
    return (
      <div className="animated fadeIn">
        <h1>{publicMarket ? publicMarket.title : "chargement en cours"}</h1>

        {!publicMarket || !constants.items ? (
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
        ) : (
          <div>
            <div className="row mb-5 px-3">
    
              <div className="col-md-6 preview">
              
                { publicMarket.image != "" && 
                <div className="form-group row">
                  <div className="col-sm-12 text-center mb-3">
                    
                    <img 
                    style={{maxHeight:100}}
                    src={storageHelper(publicMarket)} />
                  
                  
                  </div>
                </div>
                }
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Référence</label>
                    <div className="col-sm-7">
                        {publicMarket.reference}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Catégories</label>
                    <div className="col-sm-7">
                        {cat.join(', ')}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Type</label>
                    <div className="col-sm-7">
                        {(constants.items.typesMP && publicMarket.type) ? constants.items.typesMP.find(cat=> cat.id == publicMarket.type).name : ""}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Titre</label>
                    <div className="col-sm-7">
                      {publicMarket.title}
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Lieu</label>
                    <div className="col-sm-7">
                    {(constants.items.locations && publicMarket.location) ? constants.items.locations.find(sour=> sour.id == publicMarket.location).name : ""}
                    </div>
                </div>

                {!isAvisDAttribution &&
                  <div className="form-group row">
                      <label htmlFor="status" className="col-sm-5 col-form-label">Valeur</label>
                      <div className="col-sm-7">
                      {(constants.items.prices && publicMarket.price) ? constants.items.prices.find(sour=> sour.id == publicMarket.price).name : ""}
                      </div>
                  </div>
                }
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Source</label>
                    <div className="col-sm-7">
                      {(constants.items.sources && publicMarket.source) ? constants.items.sources.find(sour=> sour.id == publicMarket.source).name : ""}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Parution journal</label>
                    <div className="col-sm-7">
                        {DateTime.fromISO(publicMarket.newspaperPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Parution web</label>
                    <div className="col-sm-7">
                        {DateTime.fromISO(publicMarket.webPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                </div>

                {!isAvisDAttribution &&
                  <div className="form-group row">
                      <label htmlFor="status" className="col-sm-5 col-form-label">Date d'expiration</label>
                      <div className="col-sm-7">
                      {DateTime.fromISO(publicMarket.expiredAt).toLocaleString(DateTime.DATE_SHORT)}
                      </div>
                  </div>
                }
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Emetteur</label>
                    <div className="col-sm-7">
                    {(constants.items.emittors && publicMarket.emittor) ? constants.items.emittors.find(sour=> sour.id == publicMarket.emittor).name : ""}
                    </div>
                </div>
                
    
              </div>
              <div className="col-md-6 content-block">
                <div className="form-group row">                
                    <div className="col-sm-12">
                      <div dangerouslySetInnerHTML={{ __html: publicMarket.content}} />           
                    </div>
                </div>
              </div>
    
            </div>

            <div className="text-center">
              <Link to="/marches-publics" className="btn btn-lqo btn-lqo-primary mb-3">RETOUR AUX RESULTATS</Link><br/>
              <button className="btn btn-lqo btn-lqo-secondary" onClick={() => {this.getPublicMarketPDF(publicMarket.id)}}>TELECHARGER EN PDF</button>
            </div>
          </div>
        )}

      </div>
    );
  }
}

PublicMarket.propTypes = {
  getPublicMarket: PropTypes.func.isRequired,
  publicMarkets: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  publicMarkets: state.publicMarkets,
  errors: state.errors,
  constants: state.constants,
})

export default connect(mapStateToProps, { getPublicMarket })(PublicMarket);