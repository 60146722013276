import React, { Component } from 'react'
import axios from 'axios'
import { BarLoader } from 'react-spinners'
import {api_url} from '../../../config/config'

class ResetPassword extends Component {

  constructor(props) {
    super(props)
    this.state={
      isLoading: false,
      newPassword : '',
      newPasswordConfirm : '',
      isReset: false,
      errors: {}
    }

    this.resetPassword = this.resetPassword.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })
  }

  resetPassword() {
    const userData = {
      newPassword: this.state.newPassword,
      newPasswordConfirm: this.state.newPasswordConfirm
    }

    this.setState({
      isLoading: true,
      errors: {}
    })
    var token = this.props.location.pathname.split('/')[2]
    
    axios
      .post(api_url + 'users/password/reset/' + token, userData)
      .then(res => this.setState({
        isLoading: false,
        newPassword: "",
        newPasswordConfirm: "",
        isReset: true,
        errors: {}
      }))
      .catch(err => this.setState({
        isLoading: false,
        isReset: false,
        errors: err.response.data
      }))
  }

  componentDidMount() {
  }

  render() {
    
    return (
      <div className="animated fadeIn">
        <h1>Réinitialisation de votre mot de passe</h1>

        {this.state.errors.other && <div className="alert alert-danger">{this.state.errors.other}</div>}

        <div className="m-auto" style={{maxWidth: 350}}>
          {
            this.state.isReset ?
             <div className="alert alert-success">
              Votre mot de passe a bien été changé. Veuillez vous connecter avec ce nouveau mot de passe.
              </div>
              :
              <div>
              <div className="form-group mb-5">
                <small className="form-text text-muted">Entrez votre nouveau mot de passe</small>
                <input type="password" name="newPassword" className="form-control" placeholder="Mot de passe" 
                      onChange={this.changeInput}
                      value={this.state.newPassword}   
                />
                <small className="form-text text-danger">{this.state.errors.newPassword}</small>
              </div>
              <div className="form-group mb-5">
                <small className="form-text text-muted">Entrez votre nouveau mot de passe</small>
                <input type="password" name="newPasswordConfirm" className="form-control" placeholder="Mot de passe" 
                      onChange={this.changeInput}
                      value={this.state.newPasswordConfirm}   
                />
                <small className="form-text text-danger">{this.state.errors.newPasswordConfirm}</small>
              </div>
                <div className="text-center">
                  {
                    this.state.isLoading ? (
                      <BarLoader
                        widthUnit={"%"}
                        width={100}
                        color={'#bb3234'}
                      />) : (
                        <div>
                          <button onClick={this.resetPassword} className="btn btn-lqo btn-lqo-primary">RÉINITIALISER MON MOT DE PASSE</button>
                        </div>
                    )
                  }
                </div>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ResetPassword