import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            if(typeof action.payload === "string" && action.payload === "Unauthorized"){
                return {other: "Votre session a expiré. Veuillez vous reconnecter"}
            }
            
            return action.payload
            break;
        case CLEAR_ERRORS:
            return {};
        default:
            return state
    }
}