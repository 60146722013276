import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {logoutUser} from '../../../actions/authActions';

class DefaultHeader extends Component {

  constructor(props) {
    super(props)
  }
  
  render() {
    var {auth} = this.props

    return (
      <header  id="mainHeader">
        <div className="container p-0">
          <nav className="navbar navbar-expand-lg" style={{backgroundColor: "#0D66A8"}}>
            <Link className="navbar-brand" to="/"><img src="/logo_app.png" className="img-fluid" /></Link>
            <a className="navbar-brand mr-3" href="http://www.lequotidien.re/" target='_blank'><img src="/by_le_quotidien.png" className="img-fluid" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              style={{color: "white", position: "absolute", right: 0}}
              onClick={this.toggle}>
              <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto text-center">
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/veille-annonces-legales">Veille<br/>Annonces Légales</NavLink>
                </li>
                <li><div className="v-hr"></div></li>
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/veille-marches-publics">Veille<br/>Marchés Publics</NavLink>
                </li>
                <li><div className="v-hr"></div></li>
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/signature-electronique">Signature<br/>Électronique</NavLink>
                </li>
                <li><div className="v-hr"></div></li>
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/derniers-ajouts">Les derniers<br/>Ajouts</NavLink>
                </li>
                <li><div className="v-hr"></div></li>
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/qui-sommes-nous">Qui<br/>Sommes-Nous</NavLink>
                </li>
                <li><div className="v-hr"></div></li>
                <li className="nav-item">
                  <NavLink className="nav-link text-white" activeClassName="active" to="/contact">Nous<br/>Contacter</NavLink>
                </li>
              </ul>
              { !auth.isAuthenticated && 
                <NavLink className="nav-link text-white btn mr-0 mr-lg-3 my-3 my-lg-0 active" to="/login">Se connecter</NavLink>}
              { !auth.isAuthenticated && 
                <NavLink className="nav-link text-white btn active" to="/inscription/1">S'inscrire</NavLink>}

            </div>
          </nav>

        </div>

      </header>
    );
  }
}

DefaultHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, {logoutUser})(DefaultHeader))
