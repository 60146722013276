import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import SearchLegalAd from '../../Components/SearchLegalAd'
import tools from '../../../utils/tools'
import { ScaleLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { DateTime } from 'luxon'

class LegalAds extends Component {

  constructor(props) {
    super(props)
    this.state={}

    this.formatTypeCell = this.formatTypeCell.bind(this)
    this.formatSourceCell = this.formatSourceCell.bind(this)
  }

  formatTypeCell(type)
  {
    const {constants} = this.props
    let typeName = ""
    if(constants.items && type){
      let typeLA = constants.items.typesLA.find(c => c.id == type)
      if(typeLA) typeName = typeLA.name
    }

    return typeName
  }

  formatSourceCell(source)
  {
    const {constants} = this.props
    if(constants.items && source){
      let find = constants.items.sources.find(c => c.id == source)
      return find ? find.name : ""
    }else{
      return ""
    }
  }

  onPageChange(page)
  {
    var ls = tools.getSearchLegalAds()
    ls.currentPage = page
    localStorage.setItem('searchLegalAds', JSON.stringify(ls))
  }

  formatActionCell(id)
  {
    return <Link to={"annonces-legales/" + id}>VOIR LA FICHE</Link>
  }
  
  render() {

    const {legalAds, constants} = this.props
    const ls = tools.getSearchLegalAds()
    const options = {
      // defaultSortName: 'createdAt',
      noDataText: 'Aucune annonce trouvée',
      sizePerPage: 20,
      page: ls.currentPage,
      onPageChange: this.onPageChange,
      condensed: true
    }

    return (
      <div className="animated fadeIn">
        <h1>Liste des annonces légales</h1>

        <SearchLegalAd />
        
        {legalAds.items.length >= 0 && (
          <BootstrapTable containerClass="table" data={legalAds.items} bordered={false} keyField="id" options={options}
            hover pagination condensed ignoreSinglePage>
            <TableHeaderColumn dataField="reference" dataSort width="80">Ref.</TableHeaderColumn>
            <TableHeaderColumn dataField="type" dataFormat={this.formatTypeCell} dataSort>Type</TableHeaderColumn>
            <TableHeaderColumn dataField="source" dataFormat={this.formatSourceCell} dataSort width="110">Source</TableHeaderColumn>
            <TableHeaderColumn dataField="title" dataSort>Objet</TableHeaderColumn>
            <TableHeaderColumn dataField="webPublishedAt" dataFormat={cell => DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)} dataSort>Publication web</TableHeaderColumn>
            <TableHeaderColumn dataField="id" dataFormat={this.formatActionCell}></TableHeaderColumn>
          </BootstrapTable>
        )}

        {legalAds && legalAds.isLoading && <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>}

      </div>
    );
  }
}

LegalAds.propTypes = {
  legalAds: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  legalAds: state.legalAds,
  constants: state.constants,
})

export default connect(mapStateToProps, {})(LegalAds);