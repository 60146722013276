import { LOAD_PROMO_TOKEN, CHECK_PROMO_TOKEN } from '../actions/types'

const initialState = {
    token : null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_PROMO_TOKEN:
            return {
                ...state,
                token: null,
                isLoading: action.payload
            }
        case CHECK_PROMO_TOKEN:
            console.log(action)
            return {
                ...state,
                token: action.payload,
                isLoading: false
            }      
    
        default:
            return state
    }
}