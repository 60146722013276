import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { lostPasswordUser } from '../../../actions/userActions'
import { BarLoader } from 'react-spinners';

class LostPassword extends Component {

  constructor(props) {
    super(props)
    this.state={
      email : '',
      errors: {},
      isSended: false
    }

    this.lostPassword = this.lostPassword.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.auth.isAuthenticated) {
        this.props.history.push('/')
    }

    if(nextProps.errors){
        this.setState({ errors: nextProps.errors })
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })
  }

  async lostPassword() {
    const userData = {
      email: this.state.email
    }

    this.setState({isSended: await this.props.lostPasswordUser(userData)})
    
  }

  render() {
    const {errors} = this.state
    const {auth} = this.props
    return (
      <div className="animated fadeIn">
        <h1>Réinitialiser mon mot de passe</h1>

        <div className="pb-5">
          Si vous avez perdu votre mot de passe :<br/>
          1 - Entrez l’adresse email avec laquelle vous vous êtes inscrit<br/>
          2 - Nous vous enverrons un mail dans votre boite de réception avec un lien sécurisé<br/>
          3 - Une fois que vous aurez cliqué sur le lien, vous serez redirigé vers une page afin de créer un nouveau mot de passe
        </div>

        <div className="m-auto" style={{maxWidth: 350}}>
          <div className="form-group">
            <small className="form-text text-muted">Entrez votre email</small>
            <input type="email" name="email" className="form-control" aria-describedby="emailHelp" placeholder="Votre email" 
                  onChange={this.changeInput}
                  value={this.state.email} 
            />
            <small className="form-text text-danger">{errors.email}</small>
          </div>
          {
            this.state.isSended && <div className="alert alert-success">
              Vous venez de recevoir un email à l'adresse {this.state.email}, veuillez cliquer sur le lien qu'il contient.
              </div>
          }
          <div className="text-center">
            {
              auth.isLoading ? (
                <BarLoader
                  widthUnit={"%"}
                  width={100}
                  color={'#bb3234'}
                />) : (
                  <div>
                    <button onClick={this.lostPassword} className="btn btn-lqo btn-lqo-primary mb-3">ENVOYER</button><br/>
                  </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

LostPassword.propTypes = {
  lostPasswordUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { lostPasswordUser })(withRouter(LostPassword));