import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import SearchPublicMarket from '../../Components/SearchPublicMarket'
import tools from '../../../utils/tools'
import { ScaleLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { DateTime } from 'luxon'


class PublicMarkets extends Component {

  constructor(props) {
    super(props)
    this.state={}

    this.formatCategoriesCell = this.formatCategoriesCell.bind(this)
    this.formatTypeCell = this.formatTypeCell.bind(this)
    this.formatEmittorCell = this.formatEmittorCell.bind(this)
    this.formatSourceCell = this.formatSourceCell.bind(this)
    this.revertSortFunc = this.revertSortFunc.bind(this)
    this.getEmittorName = this.getEmittorName.bind(this)
  }

  formatCategoriesCell(categories)
  {
    const {constants} = this.props
    if(constants.items && categories){
      let cat = []
      for (const x of constants.items.categoriesMP) {
          if(categories.find(c => c == x.id)){
            cat.push(x.name)
          }
      }
      return cat.join('<br/>')
    }else{
      return ""
    }
  }

  formatEmittorCell(emittor)
  {
    const {constants} = this.props
    if(constants.items && emittor){
      let find = constants.items.emittors.find(c => c.id == emittor)
      return find ? find.name : ""
    }else{
      return ""
    }
  }

  formatSourceCell(source)
  {
    const {constants} = this.props
    if(constants.items && source){
      let find = constants.items.sources.find(c => c.id == source)
      return find ? find.name : ""
    }else{
      return ""
    }
  }

  formatTypeCell(type)
  {
    const {constants} = this.props
    if(constants.items && type){
      return constants.items.typesMP.find(c => c.id == type).name
    }else{
      return ""
    }
  }

  onPageChange(page)
  {
    var ls = tools.getSearchPublicMarkets()
    ls.currentPage = page
    localStorage.setItem('searchPublicMarkets', JSON.stringify(ls))
  }

  formatActionCell(id)
  {
    return <Link to={"marches-publics/" + id}>VOIR LA FICHE</Link>
  }

  revertSortFunc(a, b, order) {   // order is desc or asc
    if (order === 'asc') {
      return this.getEmittorName(a).toLowerCase() > this.getEmittorName(b).toLowerCase() ? -1 : 1;
    } else {
      return this.getEmittorName(b).toLowerCase() > this.getEmittorName(a).toLowerCase() ? -1 : 1;
    }
  }

  getEmittorName(x){
    const {constants} = this.props
    let find = constants.items.emittors.find(e => e.id == x.emittor)
    return find ? find.name : ""
  }
  
  render() {

    const {publicMarkets, constants} = this.props
    const ls = tools.getSearchPublicMarkets()
    const options = {
      // defaultSortName: 'createdAt',
      noDataText: 'Aucune annonce trouvée',
      sizePerPage: 20,
      page: ls.currentPage,
      onPageChange: this.onPageChange,
      condensed: true
    }

    return (
      <div className="animated fadeIn">
        <h1>Liste des marchés publics</h1>

        <SearchPublicMarket />
        
        {publicMarkets.items.length >= 0 && (
          <BootstrapTable containerClass="table" data={publicMarkets.items} bordered={false} keyField="id" options={options}
            hover pagination condensed ignoreSinglePage>
            <TableHeaderColumn dataField="reference" dataSort width="80">Ref.</TableHeaderColumn>
            <TableHeaderColumn dataField="categories" dataFormat={this.formatCategoriesCell} dataSort>Type de marché</TableHeaderColumn>
            <TableHeaderColumn dataField="type" dataFormat={this.formatTypeCell} dataSort>Type d'annonce</TableHeaderColumn>
            <TableHeaderColumn dataField="emittor" dataFormat={this.formatEmittorCell} dataSort sortFunc={ this.revertSortFunc }>Emetteur</TableHeaderColumn>
            <TableHeaderColumn dataField="source" dataFormat={this.formatSourceCell} dataSort>Source</TableHeaderColumn>
            <TableHeaderColumn dataField="title" dataSort>Objet</TableHeaderColumn>
            <TableHeaderColumn dataField="webPublishedAt" dataFormat={cell => DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)} dataSort>Publication web</TableHeaderColumn>
            <TableHeaderColumn dataField="expiredAt" dataFormat={cell => cell ? DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT) : "-"} dataSort>Expiration</TableHeaderColumn>
            <TableHeaderColumn dataField="id" dataFormat={this.formatActionCell} width="150"></TableHeaderColumn>
          </BootstrapTable>
        )}

        {publicMarkets && publicMarkets.isLoading && <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>}

      </div>
    );
  }
}

PublicMarkets.propTypes = {
  publicMarkets: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  publicMarkets: state.publicMarkets,
  constants: state.constants,
})

export default connect(mapStateToProps, {})(PublicMarkets);