import { LOAD_PUBLICMARKETS, GET_LATEST_PUBLICMARKETS, GET_PUBLICMARKETS, GET_PUBLICMARKET } from '../actions/types'

const initialState = {
    items: [],
    latest: [],
    item : null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_PUBLICMARKETS:
            return {
                ...state,
                item: null,
                isLoading: action.payload
            }
            break;
        case GET_PUBLICMARKETS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false
            }
            break;
        case GET_LATEST_PUBLICMARKETS:
            return {
                ...state,
                latest: action.payload,
                items: [],
                item: null,
                isLoading: false
            }
            break;
        case GET_PUBLICMARKET:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false
            }
            break;
        
    
        default:
            return state
    }
}