import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import tools from '../../utils/tools'

class ContractDetail extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const {users, contracts, errors, constants, user, contract} = this.props
    const isValidContract = tools.isValidContract(user)
    const styles = {
      reduction: {
        
        marginTop: -8,
        background: '#06ad06',
        fontWeight: 'normal',
        color: 'white',
        borderRadius: '100%',
        padding: '8px 6px',
        fontSize: '18px',
        marginLeft: 10
      }
    }

    var offers = []
    var alertOffers= []
    var alertAdd = []
    if(constants.items) {

      constants.items.offers.forEach((offer,k) => {
        var price = (user.id != null) ? offer.prices.renewal : offer.prices.creation
        var displayPrice = <span className="offer-item offer-price">{price}€ TTC</span>
        if(contract.promoToken != null) {
          
          displayPrice = <span className="offer-item offer-price">
            
            <del style={{
              marginRight: 10,
              color: 'black',
              fontSize: '15px',
            }}>{price}€</del> 
            {Math.floor(parseFloat(price) - (10 * price / 100))}€ TTC
            <i style={styles.reduction} >-10%</i>
            </span>
        }
        
        
        offers.push(<div key={k} className="col-12 col-md-4 p-3">
          <div className={"offer-container " + ((contract.offer == offer.id && !contract.alertOpt) ? 'offer-selected' : '' )}
            onClick={() => this.props.handleOfferChange(offer.id)}>
            <span className="offer-item offer-duration">{offer.duration} mois</span>
            {displayPrice}
          </div>
        </div>)
      })

      if(user.id) {
        constants.items.offers.forEach((offer,k) => {
          var alertOffer = constants.items.alertOffers.find(o => o.id == offer.id)
          var price = (user.id != null) ? (offer.prices.renewal + alertOffer.prices.renewal) 
          : (offer.prices.creation + alertOffer.prices.creation)
          var displayPrice = <span className="offer-item offer-price">{price}€ TTC</span>
          if(contract.promoToken != null) {
            
            displayPrice = <span className="offer-item offer-price">
              
              <del style={{
                marginRight: 10,
                color: 'black',
                fontSize: '15px',
              }}>{price}€</del> 
              {Math.floor(parseFloat(price) - (10 * price / 100))}€ TTC
              <i style={styles.reduction} >-10%</i>
              </span>
          }
          
          
          alertOffers.push(<div key={k} className="col-12 col-md-4 p-3">
            <div className={"offer-container " + ((contract.offer == offer.id && contract.alertOpt) ? 'offer-selected' : '' )}
              onClick={() => this.props.handleAlertOfferChange(offer.id)}>
              <span className="offer-item offer-duration">{offer.duration} mois</span>
              <span style={{color:'#bb3537'}}>+ option surveillance</span>
              {displayPrice}
            </div>
          </div>)
        })
      }
    }
    
    return (
      <div className="animated fadeIn">

        {user.id && user.currentContract && (
          <div className="row mb-5">
            <div className="col-12">
              <label>Votre abonnement actuel</label>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-12 offset-md-4 col-md-4 p-3">
                  <div className={"current-offer-container " + (!isValidContract  ? "current-offer-disabled" : "")}>
                    {isValidContract ? 
                      <div>
                        <div className="current-offer-state">ACTIF</div>
                        <div className="current-offer-duration">jusqu'au {(new Date(user.currentContract.expiredAt)).toLocaleDateString()}</div>
                      </div>
                      :
                      <div>
                        <div className="current-offer-state">INACTIF</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}

        <div className="row mb-5">
          <div className="col-12">
            <label>{user.id ? "Étendez la durée de votre abonnement en ajoutant :" : "Choisissez votre abonnement:"}</label>
          </div>

          <div className="col-12">
            <div className="row">
              

              {offers}
            </div>
            <div className="row">
              

              {alertOffers}
            </div>
            <div className="text-center">
              <small className="form-text text-danger">{errors.offer}</small>
            </div>
          </div>

        </div>

        <div className="row mb-5">
          <div className="col-12">
            <label>Finalisez votre inscription:</label>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6 p-3">
                <div className={"modep-container "+(contract.paymentType == 1 ? 'modep-selected' : '' )}
                  onClick={() => this.props.handlePaymentTypeChange(1)}>
                  <span className="modep-item modep-label">CRÉER MON COMPTE ET PAYER PAR</span>
                  <span className="modep-item modep">CARTE BANCAIRE</span>
                </div>
              </div>

              <div className="col-12 col-md-6 p-3">
                <div className={"modep-container "+(contract.paymentType == 2 ? 'modep-selected' : '' )}
                  onClick={() => this.props.handlePaymentTypeChange(2)}>
                  <span className="modep-item modep-label">CRÉER MON COMPTE ET PAYER PAR</span>
                  <span className="modep-item modep">CHÈQUE</span>
                </div>
              </div>
            </div>

            <div className="text-center">
              <small className="form-text text-danger">{errors.paymentType}</small>
            </div>
          </div>

        </div>

        <div className="text-center mb-3">
          {
            (users.isLoading || contracts.isLoading)  ? 
              (<ScaleLoader color={"#BB3234"} />) 
              : 
              (<button onClick={this.props.createContract} className="btn btn-lqo btn-lqo-primary">
                {user.id ? "ÉTENDRE LA DURÉE DE MON ABONNEMENT" : "M'INSCRIRE ET PAYER"}
              </button>)
          }
        </div>

      </div>
    );
  }
}

ContractDetail.propTypes = {
  users: PropTypes.object.isRequired,
  contracts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  users: state.users,
  contracts: state.contracts,
  errors: state.errors,
  constants: state.constants,
})

export default connect(mapStateToProps, { })(ContractDetail);