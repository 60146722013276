import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { searchLegalAds } from '../../actions/legalAdActions'
import tools from '../../utils/tools'
import { ScaleLoader } from 'react-spinners'

class SearchLegalAd extends Component {

  constructor(props) {
    super(props)
    this.state = {
      words: '',
      isFullWord: false,
      dBeginPublished: '',
      dEndPublished: '',
      type: '',
      source: '',
    }
    
    this.setLastSearch = this.setLastSearch.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.search = this.search.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount()
  {
    this.setLastSearch()
  }

  setLastSearch()
  {
    const ls = tools.getSearchLegalAds()
    this.setState({
      words: ls.words,
      isFullWord: ls.isFullWord,
      dBeginPublished: ls.dBeginPublished,
      dEndPublished: ls.dEndPublished,
      type: ls.type,
      source: ls.source,
    }, () => this.search(ls.currentPage))
  }

  resetSearch()
  {
    const searchData = {
      words: "",
      isFullWord: false,
      dBeginPublished: "",
      dEndPublished: "",
      type: "",
      source: "",
    }
    this.setState(searchData, () => this.search())

    //on reset dans le localStorage la recherche
    localStorage.removeItem('searchLegalAds')
  }

  changeInput(e) {
    const name = e.target.name
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    this.setState({
      [name] : value
    })
  }

  search(page = 1) {
    const searchData = {
      words: this.state.words,
      isFullWord: this.state.isFullWord,
      dBeginPublished: this.state.dBeginPublished,
      dEndPublished: this.state.dEndPublished,
      type: this.state.type,
      source: this.state.source,
    }

    //on save dans le localStorage la recherche
    var ls = searchData
    ls.searchDate = new Date
    ls.currentPage = page
    localStorage.setItem('searchLegalAds', JSON.stringify(ls))

    this.props.searchLegalAds(searchData)
  }

  render() {
    const {errors, constants, legalAds} = this.props

    let typesLA = []
    if(constants.items && constants.items.typesLA) {
      constants.items.typesLA.forEach((element,k) => {
        typesLA.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }

    let sources = []
    if(constants.items && constants.items.sources) {
      constants.items.sources.forEach((element,k) => {
        sources.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    
    return (
      <div className="mb-5">
        <div className="row">

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="words" className="col-12 col-sm-2 col-form-label text-left">Réf. ou Mot</label>
              <div className="col-12 col-sm-10">
                <input type="text" className="form-control" name="words" id="words" placeholder="Ex: Construction, bâtiment, etc..."
                  onChange={this.changeInput} value={this.state.words} />
                <small className="form-text text-danger">{errors.words}</small>
                
                <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" id="isFullWord" name="isFullWord"
                    value={this.state.isFullWord} onChange={this.changeInput} checked={this.state.isFullWord}/>
                  <label className="form-check-label" htmlFor="isFullWord" style={{lineHeight:"13px"}}>uniquement les annonces avec tous les mots</label>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group row">
              <label htmlFor="dBeginPublished" className="col-12 col-sm-2 col-md-4 col-form-label text-left">Parution du</label>
              <div className="col-12 col-sm-10 col-md-8">
                <input type="date" className="form-control" name="dBeginPublished" id="dBeginPublished" placeholder="jj/mm/aaaa"
                  onChange={this.changeInput} value={this.state.dBeginPublished} />
                <small className="form-text text-danger">{errors.dBeginPublished}</small>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group row">
              <label htmlFor="dEndPublished" className="col-12 col-sm-2 col-md-4 col-form-label text-left">au</label>
              <div className="col-12 col-sm-10 col-md-8">
                <input type="date" className="form-control" name="dEndPublished" id="dEndPublished" placeholder="jj/mm/aaaa"
                  onChange={this.changeInput} value={this.state.dEndPublished} />
                <small className="form-text text-danger">{errors.dEndPublished}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="type" className="col-12 col-sm-2 col-form-label text-left">Type</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="type" onChange={this.changeInput} value={this.state.type}>
                  <option key='-1' value="-1">— Tous les types —</option>
                  {typesLA}
                </select>
                <small className="form-text text-danger">{errors.type}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="source" className="col-12 col-sm-2 col-form-label text-left">Source</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="source" onChange={this.changeInput} value={this.state.source}>
                  <option key='-1' value="-1">— Toutes les sources —</option>
                  {sources}
                </select>
                <small className="form-text text-danger">{errors.source}</small>
              </div>
            </div>
          </div>

        </div>

        <div className="text-center">
          <div className="text-danger mb-2">
            <a href="javascript:undefined" onClick={this.resetSearch}>Réinitialiser ma recherche</a>
          </div>
          <div>
          {
              legalAds.isLoading ?
              <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
                :
              <div>
                <button onClick={() => {this.search()}} className="btn btn-lqo btn-lqo-primary">RECHERCHER</button>
                {legalAds.items.length > 0 && <span className="pl-3 text-danger">{legalAds.items.length} résultat(s)</span>}
              </div>
            }
          </div>
        </div>

      </div>
    );
  }
}

SearchLegalAd.propTypes = {
  legalAds: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
  searchLegalAds: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  legalAds: state.legalAds,
  constants: state.constants,
  errors: state.errors,
})

export default connect(mapStateToProps, { searchLegalAds })(SearchLegalAd);