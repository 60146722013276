import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import axios from 'axios'
import {api_url} from '../../../config/config'

class PaymentCB extends Component {

  constructor(props) {
    super(props)
    this.state={
      price: 0,
      formData: null,
      isLoading: true,
      error: null
    }
  }

  componentDidMount() {
    axios
      .get(api_url+'payments/form/'+this.props.match.params.id)
      .then(res => {
        this.setState({
          formData: res.data,
          isLoading: false
        })
      })
      .catch(err => {
        this.setState({
          error: err.response.data,
          isLoading: false
        })
      })
  }

  render() {
    const {formData, isLoading, error} = this.state

    var form = []
    if(formData){
      for(const k in formData){
        form.push(<input key={k} type="hidden" name={k} value={formData[k]} />)
      }
    }
    

    return (
      <div className="animated fadeIn">

        <h1>Paiement CB</h1>

        {isLoading ? (
          <ScaleLoader className="text-center" color={"#BB3234"} />
        )
        : 
        (
          <div className="text-center">
            {error ? 
              <div className="alert alert-danger">{error}</div> 
              : 
              <div>
                <p>
                  L'Officiel de la Réunion utilise la plateforme de la Banque Populaire.<br/>
                  Pour votre paiement, vous êtes redirigé vers le site bancaire sécurisé CyberPlus qui traite votre achat en ligne.<br/>
                  A aucun moment nous ne mémorisons vos données bancaires
                </p>
                <div className="row">
                  <div className="col-12 col-lg-6 offset-lg-3">
                    <div className="alert alert-primary">
                      <h3>Montant à payer: <br/><span style={{fontSize: 30}}>{formData.vads_amount /  100}€</span></h3>
                      <form name="paymentForm" method="post" action="https://paiement.systempay.fr/vads-payment/">
                        {form}
                        <div>
                          <button type="submit" className="btn btn-primary btn-lg">
                            <i className="fa fa-credit-card mr-2"></i>Procéder au paiement
                          </button>
                        </div>                  
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          }
            
          </div>
        )}

      </div>
    )
  }
}

PaymentCB.propTypes = {
  contracts: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  contracts: state.contracts
})

export default connect(mapStateToProps, { })(withRouter(PaymentCB));