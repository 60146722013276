import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

class Error extends Component {

  render() {
      const error = this.props.errors.other

    return error ? 
        (<div className="alert alert-danger my-3">
            {this.props.errors.other}
        </div>)
        :
        <div></div>
  }
}

Error.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {})(Error);