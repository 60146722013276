import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { searchPublicMarkets } from '../../actions/publicMarketActions'
import tools from '../../utils/tools'
import { ScaleLoader } from 'react-spinners'

class SearchPublicMarket extends Component {

  constructor(props) {
    super(props)
    this.state = {
      words: '',
      isFullWord: false,
      dBeginPublished: '',
      dEndPublished: '',
      categories: [],
      type: '',
      emittor: '',
      location: '',
      price: '',
      isExpired: false,
    }
    
    this.setLastSearch = this.setLastSearch.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.search = this.search.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount()
  {
    this.setLastSearch()
  }

  setLastSearch()
  {
    const ls = tools.getSearchPublicMarkets()
    if(!ls.categories) ls.categories = []

    this.setState({
      words: ls.words,
      isFullWord: ls.isFullWord,
      dBeginPublished: ls.dBeginPublished,
      dEndPublished: ls.dEndPublished,
      categories: ls.categories,
      type: ls.type,
      emittor: ls.emittor,
      source: ls.source,
      location: ls.location,
      price: ls.price,
      isExpired: ls.isExpired,
    }, () => this.search(ls.currentPage))
  }

  resetSearch()
  {
    const searchData = {
      words: "",
      isFullWord: false,
      dBeginPublished: "",
      dEndPublished: "",
      categories: [],
      type: "",
      emittor: "",
      source: "",
      location: "",
      price: "",
      isExpired: false,
    }
    this.setState(searchData, () => this.search())

    //on reset dans le localStorage la recherche
    localStorage.removeItem('searchPublicMarkets')
  }

  changeInput(e) {
    var state = this.state
    const name = e.target.name
    var value = e.target.value

    if(name == "categories"){
      if(e.target.checked){
        state.categories.push(value)
      }else{
        state.categories = state.categories.filter(x => x != value)
      }
      value = state.categories

    }else{
      value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }
    this.setState({
      [name] : value
    })
  }
  

  search(page = 1) {
    const searchData = {
      words: this.state.words,
      isFullWord: this.state.isFullWord,
      dBeginPublished: this.state.dBeginPublished,
      dEndPublished: this.state.dEndPublished,
      categories: this.state.categories,
      type: this.state.type,
      emittor: this.state.emittor,
      source: this.state.source,
      location: this.state.location,
      price: this.state.price,
      isExpired: this.state.isExpired,
    }

    //on save dans le localStorage la recherche
    var ls = searchData
    ls.searchDate = new Date
    ls.currentPage = page
    localStorage.setItem('searchPublicMarkets', JSON.stringify(ls))

    this.props.searchPublicMarkets(searchData)
  }

  render() {
    const {errors, constants, publicMarkets} = this.props
    let categories = []
    // if(constants.items && constants.items.categoriesMP) {
    //   constants.items.categoriesMP.forEach((element,k) => {
    //     categories.push(<option key={k} value={element.id}>{element.name}</option>)
    //   });
    // }
    if(constants.items && constants.items.categoriesMP) {
      constants.items.categoriesMP.forEach((c,k) => {
        var isChecked = this.state.categories.find(x => c.id.toString() == x)
        categories.push(
          <div className="form-check form-check-inline" key={'categories_' + c.id}>
            <input className="form-check-input" type="checkbox" id={'categories_' + c.id} 
              name="categories" value={c.id} onChange={this.changeInput} checked={isChecked ? "checked" : ""} />
            <label className="form-check-label" htmlFor={'categories_' + c.id}>{c.name}</label>
          </div>
        )
      })
    }
    
    let types = []
    if(constants.items && constants.items.typesMP) {
      constants.items.typesMP.forEach((element,k) => {
        types.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    let emittors = []
    if(constants.items && constants.items.emittors) {
      constants.items.emittors.forEach((element,k) => {
        emittors.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    let sources = []
    if(constants.items && constants.items.sources) {
      constants.items.sources.forEach((element,k) => {
        sources.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    let locations = []
    if(constants.items && constants.items.locations) {
      constants.items.locations.forEach((element,k) => {
        locations.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    let prices = []
    if(constants.items && constants.items.prices) {
      constants.items.prices.forEach((element,k) => {
        prices.push(<option key={k} value={element.id}>{element.name}</option>)
      });
    }
    
    return (
      <div className="mb-5">
        <div className="row">

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="words" className="col-12 col-sm-2 col-form-label text-left">Réf. ou Mot</label>
              <div className="col-12 col-sm-10">
                <input type="text" className="form-control" name="words" id="words" placeholder="Ex: Construction, bâtiment, etc..."
                  onChange={this.changeInput} value={this.state.words} />
                <small className="form-text text-danger">{errors.words}</small>
                
                <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" id="isFullWord" name="isFullWord"
                    value={this.state.isFullWord} onChange={this.changeInput} checked={this.state.isFullWord}/>
                  <label className="form-check-label" htmlFor="isFullWord" style={{lineHeight:"13px"}}>uniquement les marchés avec tous les mots</label>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group row">
              <label htmlFor="dBeginPublished" className="col-12 col-sm-2 col-md-4 col-form-label text-left">Parution du</label>
              <div className="col-12 col-sm-10 col-md-8">
                <input type="date" className="form-control" name="dBeginPublished" id="dBeginPublished" placeholder="jj/mm/aaaa"
                  onChange={this.changeInput} value={this.state.dBeginPublished} />
                <small className="form-text text-danger">{errors.dBeginPublished}</small>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group row">
              <label htmlFor="dEndPublished" className="col-12 col-sm-2 col-md-4 col-form-label text-left">au</label>
              <div className="col-12 col-sm-10 col-md-8">
                <input type="date" className="form-control" name="dEndPublished" id="dEndPublished" placeholder="jj/mm/aaaa"
                  onChange={this.changeInput} value={this.state.dEndPublished} />
                <small className="form-text text-danger">{errors.dEndPublished}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="emittor" className="col-12 col-sm-2 col-form-label text-left">Emetteur</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="emittor" onChange={this.changeInput} value={this.state.emittor}>
                  <option key='-1' value="-1">— Tous les emetteurs —</option>
                  {emittors}
                </select>
                <small className="form-text text-danger">{errors.emittor}</small>
              </div>
            </div>
          </div>
          
          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="source" className="col-12 col-sm-2 col-form-label text-left">Source</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="source" onChange={this.changeInput} value={this.state.source}>
                  <option key='-1' value="-1">— Toutes les sources —</option>
                  {sources}
                </select>
                <small className="form-text text-danger">{errors.source}</small>
              </div>
            </div>
          </div>
          
          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="locations" className="col-12 col-sm-2 col-form-label text-left">Lieu</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="emittor" onChange={this.changeInput} value={this.state.locations}>
                  <option key='-1' value="-1">— Tous les lieux —</option>
                  {locations}
                </select>
                <small className="form-text text-danger">{errors.locations}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="categories" className="col-12 col-sm-2 col-form-label text-left">Type de marché</label>
              <div className="col-12 col-sm-10">
                {categories}
                <small className="form-text text-danger">{errors.categories}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="type" className="col-12 col-sm-2 col-form-label text-left">Type d'annonce</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="type" onChange={this.changeInput} value={this.state.type}>
                  <option key='-1' value="-1">— Tous les types —</option>
                  {types}
                </select>
                <small className="form-text text-danger">{errors.type}</small>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="price" className="col-12 col-sm-2 col-form-label text-left">Valeur</label>
              <div className="col-12 col-sm-10">
                <select className="form-control" name="price" onChange={this.changeInput} value={this.state.price}>
                  <option key='-1' value="-1">— Toutes les valeurs —</option>
                  {prices}
                </select>
                <small className="form-text text-danger">{errors.price}</small>
              </div>
            </div>
          </div>

          

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="words" className="col-12 col-sm-2 col-form-label text-left"></label>
              <div className="col-12 col-sm-10">
                <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" id="isExpired" name="isExpired"
                    value={this.state.isExpired} onChange={this.changeInput} checked={this.state.isExpired}/>
                  <label className="form-check-label" htmlFor="isExpired" style={{lineHeight:"13px"}}>Inclure les marchés expirés</label>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <div className="text-danger mb-2">
            <a href="javascript:undefined" onClick={this.resetSearch}>Réinitialiser ma recherche</a>
          </div>
          <div>
            {
              publicMarkets.isLoading ?
              <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
                :
              <div>
                <button onClick={() => {this.search()}} className="btn btn-lqo btn-lqo-primary">RECHERCHER</button>
                {publicMarkets.items.length > 0 && <span className="pl-3 text-danger">{publicMarkets.items.length} résultat(s)</span>}
              </div>
            }
          </div>
        </div>

      </div>
    );
  }
}

SearchPublicMarket.propTypes = {
  publicMarkets: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
  searchPublicMarkets: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  publicMarkets: state.publicMarkets,
  constants: state.constants,
  errors: state.errors,
})

export default connect(mapStateToProps, { searchPublicMarkets })(SearchPublicMarket);