import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import axios from 'axios'
import {api_url} from '../../../config/config'

class ReturnPayment extends Component {

  constructor(props) {
    super(props)
    this.state={
      price: 0,
      form: null
    }
  }
  
  componentDidMount() {
    axios
      .get(api_url+'contracts/'+this.props.match.params.idContract+'/price')
      .then(res => {
        var price = res.data.total
        this.setState({price})
      })
  }

  render() {
    const {contracts} = this.props
    const paymentType = this.props.match.params.type

    var returnTxt = ""
    const btnRetest = <Link to={"/paiement/" + this.props.match.params.idContract} className="btn btn-primary btn-lg mt-3">
                        <i className="fa fa-credit-card mr-2"></i>Cliquez ici pour ré-essayer
                      </Link>
    switch (this.props.match.params.return) {
      case "cancel":
        returnTxt = <div className="col-lg-6 offset-lg-3 alert alert-danger font-weight-bold">La transaction a été annulé.<br/>{btnRetest}</div>
        break;
      case "error":
        returnTxt = <div className="col-lg-6 offset-lg-3 alert alert-danger font-weight-bold">Une erreur est survenu lors du paiement.<br/>{btnRetest}</div>
        break;
      case "refused":
        returnTxt = <div className="col-lg-6 offset-lg-3 alert alert-danger font-weight-bold">Le paiement a été refusé par votre établissement bancaire.<br/>{btnRetest}</div>
        break;
      case "success":
        returnTxt = <div>
          <p>Si il s'agit d'une création de compte, vous allez recevoir un email d’activation.</p>
          <p>Merci de cliquer sur le lien à l’intérieur de celui-ci pour valider votre adresse email.</p>
          <p>Vous pourrez ensuite utiliser votre espace client et recevoir vos alertes.</p>
        </div>
        break;
    
      default:
        break;
    }

    return (
      <div className="animated fadeIn">

        <h1>Paiement de votre abonnement</h1>

        {contracts.isLoading ? (
          <ScaleLoader className="text-center" color={"#BB3234"} />
        )
        : 
        (
          <div className="text-center">
            {this.props.match.params.return == "success" && <div className="text-blue my-5 font-weight-bold">
              {paymentType == 1 ? 
                "MERCI. Votre paiement a été recu."
              :
                "MERCI. Votre demande d’inscription a bien été reçue."
              }
            </div>}
            <div className="my-5">
              {paymentType == 1 ? 
                <div>{returnTxt}</div>
              :
              <div>
                <p>Merci d’envoyer votre chèque d'un montant de <strong>{this.state.price} euros</strong> à l’adresse suivante :</p>
                <p>(Pour les collectivités, veuillez-nous contacter au 0262 97 52 30)</p>
                <p>
                  L’ Officiel de la Réunion<br/>
                  Service Abonnement<br/>
                  C/O Le Quotidien<br/>
                  1 rue Lislet Geoffroy<br/>
                  97490 SAINTE-CLOTILDE
                </p>
                <p><strong>Veuillez préciser au dos de votre chèque, vos nom et prénom utilisés lors de votre inscription.</strong></p>
                <p>Dès réception de votre paiement, nous vous enverrons un email d’activation.</p>
                <p>Merci de cliquer sur le lien à l’intérieur de celui-ci pour valider votre adresse email.</p>
                <p>Vous pourrez ensuite utiliser votre espace client et recevoir vos alertes.</p>
              </div>
              }
            </div>
          </div>
        )}

      </div>
    )
  }
}

ReturnPayment.propTypes = {
  contracts: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  contracts: state.contracts
})

export default connect(mapStateToProps, { })(withRouter(ReturnPayment));