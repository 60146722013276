import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_PROMO_TOKEN, CHECK_PROMO_TOKEN} from './types'
import { api_url } from '../config/config'

// Create user
export const checkPromoToken = (data) => dispatch => {

    return new Promise((resolve, reject) => {
        dispatch({type: CLEAR_ERRORS, payload: {}})
        dispatch({type: LOAD_PROMO_TOKEN, payload: true})
        
        axios.get(api_url + 'promos/'+data )
            .then(res => {
                dispatch({type: CHECK_PROMO_TOKEN, payload: res.data})
                resolve(res.data)
            })
            .catch(err => {
                dispatch({type: LOAD_PROMO_TOKEN, payload: false})
                dispatch({type: GET_ERRORS, payload: err.response.data})
                reject(err.response.data)
            })
    })
}
