const tools = {
    getSearchLegalAds: () => {
        const ls = JSON.parse(localStorage.getItem('searchLegalAds'))
        if(ls) return ls

        return {
            currentPage: 1,
            words: '',
            isFullWord: false,
            dBeginPublished: "",
            dEndPublished: "",
            type: "",
            source: "",
        }
    },
    getSearchPublicMarkets: () => {
        const ls = JSON.parse(localStorage.getItem('searchPublicMarkets'))
        if(ls) return ls

        return {
            currentPage: 1,
            words: '',
            isFullWord: false,
            dBeginPublished: "",
            dEndPublished: "",
            categories: [],
            isExpired: false,
            emittor: '',
            source: '',
            type: '',
            price: '',

        }
    },
    isValidContract: (user) => {
        if(user == null) return false
        if(user.status == "INACTIF") return false
        if(user.currentContract == null || user.currentContract.expiredAt == null || new Date(user.currentContract.expiredAt) < new Date()) return false
        return true
    }
}

export default tools