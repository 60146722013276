import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import { api_url } from '../../config/config'

class UserContract extends Component {

  constructor(props)
  {
    super(props)
    this.getContractPDF = this.getContractPDF.bind(this)
  }

  getContractPDF(id)
  {
    window.open(api_url + 'pdf/factures/' + id + '/' + localStorage.getItem('jwtToken').replace('Bearer ', ''))
  }

  render() {
    const {users} = this.props
    const userContracts = []
    
    if(users.item && users.item.contracts){
      users.item.contracts.forEach(c => {
        var offer
        if(c.offer == 1) offer = "12 MOIS"
        else if(c.offer == 2) offer = "6 MOIS"
        else if(c.offer == 3) offer = "3 MOIS"
        
        if(c.alertOpt == true) offer += " + surveillance" 

        var paymentType
        if(c.paymentType == 1) paymentType = "CB"
        else if(c.paymentType == 2) paymentType = "CHÈQUE"
        else if(c.paymentType == -1) paymentType = "OFFERT"

        var paymentState = null
        if(c.isValidated) {
          if(c.paymentType == -1){
            paymentState = <span className="text-primary">Offert le {(new Date(c.validatedAt)).toLocaleDateString()}</span>
          }else{
            paymentState = <span className="text-success">Payé le {(new Date(c.validatedAt)).toLocaleDateString()}</span>
          }
        }
        else {
          paymentState = <div>
              <span className="text-danger mr-3"><i className="fa fa-exclamation-triangle mr-2"></i> En attente de paiement</span>
            </div>
        }
  
        userContracts.push(
          <tr key={c.id}>
            <td>{c.reference}</td>
            <td>{c.beginAt && (new Date(c.beginAt)).toLocaleDateString()}</td>
            <td>{c.expiredAt && (new Date(c.expiredAt)).toLocaleDateString()}</td>
            <td>{offer}</td>
            <td>{paymentType}</td>
            <td>{paymentState}</td>
            <td>
              {
                c.isValidated &&
                <button className="btn btn-sm btn-lqo btn-lqo-primary" onClick={() => {this.getContractPDF(c.id)}}>
                  <i className="fa fa-file-download mr-2"></i> TÉLÉCHARGER
                </button>
              }
            </td>
          </tr>
        )
      })
    }

    return <div>
      <h1>Historique des factures</h1>
      {users.isLoading ? (
        <div className="text-center">
          <ScaleLoader color={"#BB3234"} />
        </div>
      ):(
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Numéro de facture</th>
              <th scope="col">Début</th>
              <th scope="col">Fin</th>
              <th scope="col">Type</th>
              <th scope="col">Moyen de paiement</th>
              <th scope="col">Etat</th>
              <th scope="col">Télécharger</th>
            </tr>
          </thead>
          <tbody>
            {userContracts}
          </tbody>
        </table>
      )}
    </div>
  }
}

UserContract.propTypes = {
  users: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps, {})(UserContract);