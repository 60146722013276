import { LOAD_COMPANIES, GET_COMPANIES, SELECT_COMPANY } from '../actions/types'

const initialState = {
    items: null,
    selected: null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_COMPANIES:
            return {
                ...state,
                items: null,
                selected: null,
                isLoading: action.payload
            }
            break;
        case GET_COMPANIES:
            return {
                ...state,
                items: action.payload,
                selected: null,
                isLoading: false
            }
            break;
        case SELECT_COMPANY:
            return {
                ...state,
                selected: action.payload
            }
            break;

        default:
            return state
    }
}