import React, { Component } from 'react'


class AdSide extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    
    return (
      <div className="col py-4 text-center">
        <iframe id='a11a0ab8' name='a11a0ab8' src='https://servedby.revive-adserver.net/afr.php?n=a11a0ab8&amp;zoneid=503&amp;cb=72004983381586069021' frameBorder='0' scrolling='no' width='160' height='600'>
          <a href='https://servedby.revive-adserver.net/ck.php?n=a05a2b3e&amp;cb=72004983381586069021' target='_blank'>
            <img src='https://servedby.revive-adserver.net/avw.php?zoneid=503&amp;cb=72004983381586069021&amp;n=a05a2b3e' border='0' alt='' />
          </a>
        </iframe>
        <script type='text/javascript' src='https://servedby.revive-adserver.net/ag.php'></script>
      </div>
    )
  }
}



export default AdSide