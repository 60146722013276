import { GET_CURRENT_USER, LOAD_CONTRACTS } from '../actions/types'

const initialState = {
    items : null,
    item : null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_CONTRACTS:
            return {
                ...state,
                items: null,
                item: null,
                isLoading: action.payload
            }
            break;
    
        default:
            return state
    }
}