import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_PUBLICMARKETS, GET_PUBLICMARKETS, GET_PUBLICMARKET, GET_LATEST_PUBLICMARKETS} from './types'
import { api_url } from '../config/config'
import { DateTime } from 'luxon'

// Get latest publicmarkets (homepage)
// export const getLatestPublicMarkets = () => dispatch => {

//     dispatch({type: CLEAR_ERRORS, payload: {}})
//     dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
//     axios.get(api_url + 'publicmarkets/latest')
//         .then(res => {
//             dispatch({type: GET_LATEST_PUBLICMARKETS, payload: res.data})
//         })
//         .catch(err => {
//             dispatch({type: LOAD_PUBLICMARKETS, payload: false})
//             dispatch({type: GET_ERRORS, payload: err.response.data})
//         })
// }

// Search publicmarkets
export const searchPublicMarkets = (searchData) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    //on traite les dates si jamais elles sont mal formatées
    if(searchData.dBeginPublished != "" && !DateTime.fromFormat(searchData.dBeginPublished, "yyyy-MM-dd").isValid){
        searchData.dBeginPublished = DateTime.fromFormat(searchData.dBeginPublished, "dd/MM/yyyy").toFormat('yyyy-MM-dd')
    }
    if(searchData.dEndPublished != "" && !DateTime.fromFormat(searchData.dEndPublished, "yyyy-MM-dd").isValid){
        searchData.dEndPublished = DateTime.fromFormat(searchData.dEndPublished, "dd/MM/yyyy").toFormat('yyyy-MM-dd')
    }

    axios.post(api_url + 'publicmarkets/search', searchData)
        .then(res => {
            dispatch({type: GET_PUBLICMARKETS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get all publicmarkets
export const getPublicMarkets = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets')
        .then(res => {
            dispatch({type: GET_PUBLICMARKETS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get specific publicmarkets
export const getPublicMarket = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets/' + id)
        .then(res => {
            dispatch({type: GET_PUBLICMARKET, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get specific publicmarkets
export const getLatestPublicMarkets = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets/latest/additions')
        .then(res => {
            dispatch({type: GET_LATEST_PUBLICMARKETS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

