import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_CONSTANTS, GET_CONSTANTS} from './types'
import { api_url } from '../config/config'

// Get all logs from a user
export const getConstants = () => dispatch => {
    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CONSTANTS, payload: true})
    
    axios.get(api_url + 'constants')
        .then(res => {
            dispatch({type: GET_CONSTANTS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_CONSTANTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}