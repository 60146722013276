import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios'
import { ScaleLoader } from 'react-spinners'
import {api_url} from '../../../config/config'

class Activation extends Component {

  constructor(props) {
    super(props)
    this.state= {
      isLoading: false,
      errors: null
    }
  }

  componentDidMount() {
    this.setState({isLoading: true})
    var token = this.props.location.pathname.split('/')[2]
    
    axios
      .get(api_url + 'auth/init/' + token)
      .then(res => this.setState({isLoading: false}))
      .catch(err => this.setState({errors: err.response.data}))
  }

  render() {
    return (
      <div className="animated fadeIn">
        <h1>Vérification de votre adresse email</h1>
        
        {this.state.errors && <div className="alert alert-danger">{this.state.errors.other}</div>}

        <div className="text-center">
          {!this.state.errors && 
            (this.state.isLoading ? (
              <ScaleLoader color={"#BB3234"} />
              ) : (
                <div className="text-blue font-18">
                  MERCI. votre adresse email a bien été vérifiée.
                </div>
              ))
          }
        </div>
      </div>
    );
  }
}

export default Activation