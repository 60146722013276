import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import axios from 'axios'
import { ScaleLoader } from 'react-spinners'
import { api_url } from '../../../../config/config';
import { addAlert, getAlert, updateAlert } from '../../../../actions/alertActions'

class AlertDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id:null,
      name: '',
      group: 1,
      type: -1,
      keywords: '',
      isFullWord: false,
      typesMP: [],
      price: -1,
    }
    this.changeInput = this.changeInput.bind(this)
    this.changeCheckbox = this.changeCheckbox.bind(this)
    this.addAlert = this.addAlert.bind(this)
  }

  componentDidMount() {
    var id = this.props.match.params.id
    if(id !='create') {
      this.props.getAlert(id)
    }
  }
  
  componentWillReceiveProps(nextProp) {    
    if(nextProp.alerts.item) {
      this.setState(nextProp.alerts.item)
    }
  }

  
  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    var alert = this.state

    if(e.target.type == "checkbox"){
      if(name == "categories"){
        if(e.target.checked){
          alert.typesMP.push(value)
        }else{
          alert.typesMP = alert.typesMP.filter(x => x != value)
        }
        value = alert.typesMP
      }else{
        value = e.target.checked
      }
    }

    alert[name] = value

    this.setState(alert)
  }
  changeCheckbox(e) {
    var {alert} = {...this.state}
    alert.isFullWord = !alert.isFullWord
    this.setState({alert})
  }

  addAlert() {
    if(this.state.id) {
      this.props.updateAlert(this.state)
    }
    else {
      this.props.addAlert(this.state)
    }
  }
  

  render() {
    
    const { constants, errors, alerts } = this.props
    const alert = this.state
    var publicMarketFields = ''
    var types = [<option key="typ0" value=""></option>]
    var groups = [<option key="grp0" value=""></option>]

    if(constants.items) {
      constants.items.adTypes.forEach((group,k) => {
        groups.push(<option key={k} value={group.id}>{group.name}</option>)
      })

      var linkTo = constants.items.adTypes.find(elem => elem.id == alert.group).linkTo
      
      constants.items[linkTo].forEach((cat,k) => {
        types.push(<option key={k} value={cat.id}>{cat.name}</option>)
      })
      
      if(alert.group == '1') {
        let typesMP = []
        let prices = [
          <option key='-1' value="-1">— Toutes les valeurs —</option>
        ]
        constants.items.categoriesMP.forEach((c,k) => {
          var isChecked = alert.typesMP.find(x => c.id.toString() == x)
          // types.push(<option key={k} value={element.id}>{element.name}</option>)
          typesMP.push(<div  key={k} className="form-check form-check-inline" key={'categories_' + c.id}>
            <input className="form-check-input" type="checkbox" id={'categories_' + c.id} 
              name="categories" value={c.id} onChange={this.changeInput} checked={isChecked ? "checked" : ""} />
            <label className="form-check-label" htmlFor={'categories_' + c.id}>{c.name}</label>
          </div>)
        });
        constants.items.prices.forEach((element,k) => {
          prices.push(<option key={k} value={element.id}>{element.name}</option>)
        });
        
        publicMarketFields = <div>
          <div className="form-group row">
            <label htmlFor="typeMP" className="col-sm-2 col-form-label">Types de marchés</label>
            <div className="col-sm-4">
              {typesMP}
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="group" className="col-sm-2 col-form-label">Valeur</label>
            <div className="col-sm-4">
              <select id="price" className="form-control" name="price" onChange={this.changeInput} value={alert.price}>
                {prices}
              </select>
              <small className="form-text text-danger">{errors.price}</small>
            </div>
          </div>
        </div>
      }
    }
    
    var title = (alert.id) ? 'Modifier une alerte' : 'Créer une nouvelle alerte'
    
    return (
      <div className="animated fadeIn">
        <h1>{title}</h1>
        <div className="col-12 ">

          <Link className="btn btn-light" to="/mes-alertes">
            <i className="fa fa-chevron-left"></i> Retour
          </Link>

          <div className="form-group row">
            <label htmlFor="group" className="col-sm-2 col-form-label">Nom</label>
            <div className="col-sm-4">
              <input type="text" id="name" className="form-control" name="name" onChange={this.changeInput} value={alert.name} />
              
              <small className="form-text text-danger">{errors.name}</small>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="group" className="col-sm-2 col-form-label">Groupe</label>
            <div className="col-sm-4">
              <select id="group" className="form-control" name="group" onChange={this.changeInput} value={alert.group}>
                <option value="1">Marchés publics</option>
                <option value="2">Annonces légales</option>
              </select>
              <small className="form-text text-danger">{errors.group}</small>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="keywords" className="col-sm-2 col-form-label">Mots clés</label>
            <div className="col-sm-4">
              <input type="text" id="keywords" className="form-control" name="keywords" onChange={this.changeInput} value={alert.keywords} />
              <small className="form-text text-danger">{errors.keywords}</small>
            </div>
            <div className="col-sm-6">
              <input style={{
                display:'inline-block',
                verticalAlign:'middle',
                width:'10%'
              }} type="checkbox" id="isFullWord" className="" name="isFullWord" onChange={this.changeInput} checked={(alert.isFullWord) ? 'checked': false} />
              <label style={{
                display:'inline-block',
                verticalAlign:'top',
                width:'89%',
                textAlign:'left'
              }} htmlFor="isFullWord" className="">Uniquement les annonces avec tous les mots</label>
              <small className="form-text text-danger">{errors.isFullWord}</small>
            </div>  
          </div>
          
          <div className="form-group row">
            <label htmlFor="type" className="col-sm-2 col-form-label">Type</label>
            <div className="col-sm-4">
              <select id="type" className="form-control" name="type" onChange={this.changeInput} value={alert.type}>
                {types}
              </select>
              <small className="form-text text-danger">{errors.type}</small>
            </div>
          </div>

          {publicMarketFields}

          <p style={{color:'#0d66a8', textAlign:'center'}}>
            Vous recevrez par email les nouvelles entrées à partir de demain.
          </p>  
        </div>

        <div className="col-md-12 mx-auto my-4 text-center">
          {alerts.isLoading ?
            <ScaleLoader className="text-center" color={"#BB3234"} />
          :
            <button onClick={this.addAlert} className="btn btn-lqo btn-lqo-primary">
              {alert.id ? "Modifier" : "Créer"}
            </button>
          }
        </div>

        {alerts.isSaved && <div className="alert alert-success animated fadeIn" role="alert">
          Vos informations ont bien été enregistrées.
        </div>}
      </div>
    );
  }
}

AlertDetail.propTypes = {
  constants: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  constants: state.constants,
  errors: state.errors,
  alerts: state.alerts
})

export default connect(mapStateToProps, { addAlert, getAlert, updateAlert })(AlertDetail);