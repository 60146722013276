import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import ModalChangePassword from './ModalChangePassword'

class UserDetail extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const {users, errors} = this.props
    const {user} = this.props
    
    return (
      <div className="animated fadeIn mb-5">
        <div className="row mb-5">

          <div className="col-12 col-md-6">

            {
              !user.id ? (
                <div>
                  <div className="form-group row">
                    <label htmlFor="login" className="col-4 col-form-label">Identifiant</label>
                    <div className="col-8">
                      <input type="text" className="form-control" name="login" id="login" placeholder=""
                        onChange={this.props.changeInput} value={user.login} />
                      <small className="form-text text-danger">{errors.login}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="email" className="col-4 col-form-label">Email</label>
                    <div className="col-8">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Ex: moi@exemple.com"
                        onChange={this.props.changeInput} value={user.email} />
                      <small className="form-text text-danger">{errors.email}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="password" className="col-4 col-form-label">Mot de passe</label>
                    <div className="col-8">
                      <input type="password" className="form-control" name="password" id="password" placeholder="******"
                        onChange={this.props.changeInput} />
                      <small className="form-text text-danger">{errors.password}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="offset-4 col-8">
                      <input type="password" className="form-control" name="passwordConfirm" id="passwordConfirm" placeholder="******"
                        onChange={this.props.changeInput} />
                      <small className="form-text text-muted">Confirmez votre mot de passe</small>
                      <small className="form-text text-danger">{errors.passwordConfirm}</small>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-group row">
                    <label htmlFor="login" className="col-4 col-form-label">Identifiant</label>
                    <div className="col-8">
                      <label className="mb-0">{user.login}</label>
                      <small className="form-text text-danger">L'identifiant n'est pas modifiable</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="email" className="col-4 col-form-label">Email</label>
                    <div className="col-8">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Ex: moi@exemple.com"
                        onChange={this.props.changeInput} value={user.email} />
                      <small className="form-text text-primary">Veuillez vous assurer que cet email est valide et que vous pouvez y accéder</small>
                      <small className="form-text text-danger">{errors.email}</small>
                    </div>
                  </div>

                  <div className="offset-3 col-9 text-center mb-2">
                    <ModalChangePassword userId={user.id} />
                  </div>
                </div>
              )
            }

          </div>

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="gender" className="col-4 col-form-label">Civilité</label>
              <div className="col-8">
                <select id="gender" className="form-control" name="gender" onChange={this.props.changeInput} value={user.gender}>
                  <option value="1">Monsieur</option>
                  <option value="2">Madame</option>
                </select>
                <small className="form-text text-danger">{errors.gender}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="lastname" className="col-4 col-form-label">Nom</label>
              <div className="col-8">
                <input type="text" className="form-control" id="lastname" name="lastname" placeholder="Ex: Payet"
                  onChange={this.props.changeInput} value={user.lastname} />
                <small className="form-text text-danger">{errors.lastname}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="firstname" className="col-4 col-form-label">Prénom</label>
              <div className="col-8">
                <input type="text" className="form-control" id="firstname" name="firstname" placeholder="Ex: Alexandre"
                  onChange={this.props.changeInput} value={user.firstname} />
                <small className="form-text text-danger">{errors.firstname}</small>
              </div>
            </div>

          </div>

        </div>

        <div className="row mb-5">

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="company" className="col-4 col-form-label">Société</label>
              <div className="col-8">
                <input type="text" className="form-control" id="company" name="company" placeholder="Ex: Exemple Sarl"
                  onChange={this.props.changeInput} value={user.company} />
                <small className="form-text text-danger">{errors.company}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="siren" className="col-4 col-form-label">SIREN</label>
              <div className="col-8">
                <input type="text" className="form-control" id="siren" name="siren" placeholder="Ex: 123 456 789"
                  onChange={this.props.changeInput} value={user.siren} />
                <small className="form-text text-danger">{errors.siren}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="phone" className="col-4 col-form-label">Téléphone</label>
              <div className="col-8">
                <input type="tel" className="form-control" id="phone" name="phone" placeholder="Ex: 0692 12 34 56"
                  onChange={this.props.changeInput} value={user.phone} />
                <small className="form-text text-danger">{errors.phone}</small>
              </div>
            </div>

          </div>

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="address" className="col-4 col-form-label">Adresse</label>
              <div className="col-8">
                <input type="text" className="form-control" id="address" name="address" placeholder="Ex: 1 rue Par Exemple"
                  onChange={this.props.changeInput} value={user.address} />
                <small className="form-text text-danger">{errors.address}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="postalCode" className="col-4 col-form-label">Code postal</label>
              <div className="col-8">
                <input type="text" className="form-control" id="postalCode" name="postalCode" placeholder="Ex: 97400"
                  onChange={this.props.changeInput} value={user.postalCode} />
                <small className="form-text text-danger">{errors.postalCode}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="city" className="col-4 col-form-label">Ville</label>
              <div className="col-8">
                <input type="text" className="form-control" id="city" name="city" placeholder="Ex: Saint Denis"
                  onChange={this.props.changeInput} value={user.city} />
                <small className="form-text text-danger">{errors.city}</small>
              </div>
            </div>

          </div>

        </div>

        <div className="text-center">
          {
            user.id && (users.isLoading ? 
              (<ScaleLoader color={"#BB3234"} />) 
              : 
              (<div>
                {users.isSaved && <div className="text-success mb-1"><i className="fa fa-check mr-1"></i>Vos informations ont bien été enregistrées</div>}
                {user.id && <button onClick={this.props.updateUser} className="btn btn-lqo btn-lqo-primary">ENREGISTRER</button>}
              </div>))
          }
        </div>

      </div>
    );
  }
}

UserDetail.propTypes = {
  users: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  users: state.users,
  errors: state.errors,
})

export default connect(mapStateToProps, { })(UserDetail);