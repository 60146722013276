import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getLatestLegalAds} from '../../actions/legalAdActions'
import { ScaleLoader } from 'react-spinners'

class LastLegal extends Component {

    componentDidMount()
    {
        this.props.getLatestLegalAds()
    }

    render() {
        const {legalAds} = this.props
        var latest = []
        legalAds.latest.forEach((a, k) => {
            latest.push(
                <p key={a.id}>
                    <span className="text-blue-alt font-weight-bold">{k + 1} - {a.title} : </span>
                    <span dangerouslySetInnerHTML={{ __html: a.content}}></span>
                </p>
            )
        })

        return (
            <div className="contentBox">
                <h2 className="lastLegal">dernières annonces légales</h2>
                <div className="p-3">{latest}</div>
                <div className="text-right">
                    {this.props.viewLegalAd}
                </div>
                {legalAds.latest && legalAds.isLoading && <ScaleLoader color={"#BB3234"} />}
            </div>
        )
    }
}

LastLegal.propTypes = {
  legalAds: PropTypes.object.isRequired,
  getLatestLegalAds: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    legalAds: state.legalAds
})

export default connect(mapStateToProps, {getLatestLegalAds})(LastLegal);