import React from 'react';

const ElectronicSign  = () => (
  <div className="animated fadeIn">
    <div className="mb-3">
      <h2>Votre signature électronique, <span className="text-red font-weight-bold">“clé” en main !</span></h2>

      <div className="container my-5">

        <div className="row mb-3">
          <div className="col-12 col-md-6 mb-3 p-3 text-center">
            <img className="mb-3" src="/img/cle_usb.svg" alt="Clé USB" width="120"/>
            <h5 className="text-blue">
              Obligatoire depuis le <strong>1er octobre 2018</strong> la signature éléctronique est l’outil 
              indispensable pour répondre aux appels d’offres dématérialisés.
            </h5>
          </div>
          <div className="col-12 col-md-6 p-3">
            <div className="frame-blue text-center text-white">
              <div className="title">COMMANDEZ-LA AVEC L’OFFICIEL, NOUS NOUS OCCUPONS DE TOUT !</div>
              <div style={{borderTop: "1px solid #BFD008", width: "50%", margin: "10px auto"}}></div>
              <ul className="list-unstyled">
                <li><strong>Commande et livraison à votre entreprise</strong> sous 6 jours ouvrés</li>
                <li className="text-green">•</li>
                <li><strong>Simplification de la démarche</strong> grâce à nos experts en signature électronique</li>
                <li className="text-green">•</li>
                <li><strong>Installation sur vos postes de travail et accompagnement pour votre prise en main</strong></li>
              </ul>
              
            </div>
            <div className="bg-blue p-2 mt-2 mb-2 text-center" style={{color:'white'}}>
                Livraison & Accompagnement à l’installation : 
                <br/><strong>317 € TTC valable 3 ans</strong>
            </div>
            <a className="btn btn-lqo-primary btn-block font-weight-bold p-3"
            href="https://portail-pki.certeurope.fr/ws/APCIT_V4" target="_BLANK"
                style={{fontSize: 25, borderRadius:0}}>
                <i className="fas fa-file-signature pr-3" style={{fontSize: 40}}></i>
                COMMANDEZ
              </a>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12 col-md-6 mb-3 p-3 text-center">
            <img src="/img/ordi.svg" alt="Clé USB" className="img-fluid"/>
          </div>
          <div className="col-12 col-md-6 p-3">
            <div className="text-blue">
              <p className="text-red font-weight-bold">CONFORME ET SECURISEE</p>
              <p>
                La signature électronique permet de valider la conformité d’un document et 
                d’authentifier le signataire. De plus, le certificat <strong>CERTEUROPE ADVANCED CA V4 
                RGS**elDAS</strong> est compatible avec tous les marchés dématérialisés français et européens.
              </p>
            </div>
          </div>
        </div>
        
        <div className="row mb-3">
          
          <div className="col-12 mb-3 text-white text-center">
          
            <div className="bg-red p-2" style={{borderRadius: "10px 10px 0 0"}}>
              <div className="row">
                <div className="col-12 col-sm-8">
                  <strong>La signature éléctronique</strong> <br/>
                  « CERTEUROPE ADVANCED CA V4 » <br/>
                  RGS**elDAS
                </div>
                <div className="col-12 col-sm-4 align-self-center">
                  <img src="/img/cle_usb_white.svg" width="50" />
                </div>
              </div>
            </div>

            
          </div>

          
        </div>

      </div>
    
    </div>
  </div>
)

export default ElectronicSign;

