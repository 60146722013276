import { image_url } from "../config/config"

const getPathImg = (entity) => {
    // let dateV3 = new Date('2020-06-24')
    // let url = "https://storage.gra.cloud.ovh.net/v1/AUTH_b3034b14ce844567a4d63bd6205e14b5/"
    // if(new Date(entity.createdAt) < dateV3){
    //     url += ("lqo_public/" + entity.image)
    // }else{
    //     url += ("lqopublic/" + entity.image)
    // }
    let url = image_url + entity.image
    return url
}

export default getPathImg