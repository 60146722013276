import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import { api_url } from '../../../config/config'


class PromoCode extends Component {

  constructor(props) {
    super(props)
    this.state={
        email: '',
        isLoading: false,
        isSent:false,
        errors: {}
    }

    this.dateValid = '01/01/2019'
    this.changeInput = this.changeInput.bind(this)
    this.sendCode = this.sendCode.bind(this)
  }

  componentDidMount()
  {
    
  }

  changeInput(e) {
    this.setState({
        email: e.target.value,
        isSent:false,
        isLoading:false
    })
  }
  sendCode() {
    this.setState({isLoading: true, errors: {}})
    axios.post(api_url+'promos', {email: this.state.email})
    .then(res => {
        this.setState({
            isLoading: false,
            isSent: true,
            errors: {}
        })
    })
    .catch(err => {
        console.log(err.response.data)
        this.setState({
            isLoading:false,
            errors: err.response.data
        })
    })
  }

  render() {

    const { auth } = this.props
    console.log(auth)

    var action = <button onClick={this.sendCode} className="btn btn-lqo btn-lqo-primary">Envoyer</button>
    if(this.state.isLoading) {
        action = <ScaleLoader className="text-center" color={"#BB3234"}/>
    }
    if(this.state.isSent) {
        action = <strong style={{color:'green'}}>Le code d'inscription a bien été envoyé</strong>
    }

    

    return (
      <div className="animated fadeIn">
        <h1>Offrir un coupon d'inscription</h1>
        <div className="col-12 text-center">
            <p>Un collaborateur, un partenaire, un ami souhaite rejoindre notre plateforme ?</p>
            <p>Invitez le en lui offrant ce coupon dont il pourra profiter dès son inscription !</p>
            <p className="text-danger">Attention, vous devez avoir un abonnement d'1 an actif pour effectuer cette action.</p>
        </div>
        <div className="col-6 mx-auto my-5">
            <div className="offer-container" style={{color:'#0DA80D'}}>
                <span className="offer-item offer-duration">-10% à l'inscription</span>
                <span className="offer-item offer-price">valable jusqu'au {this.dateValid}</span>
            </div>
        </div>
        <div className="col-6 mx-auto my-5 text-center">
            
            <label htmlFor="firstname" className="col-form-label">Email</label>              
            <input type="text" className="form-control" id="email" name="email" placeholder="Ex: alexandre@gmail.com"
                onChange={this.changeInput} value={this.state.email} />
            <small className="form-text text-danger">{this.state.errors.email}</small>
            <small className="form-text text-danger">{this.state.errors.other}</small>

            
        </div>
        <div className="col-6 mx-auto my-3 text-center">
            {action}
            
        </div>
        
      </div>
    );
  }
}

PromoCode.propTypes = {
    errors: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.auth,
})

export default connect(mapStateToProps, {})(PromoCode);