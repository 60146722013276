import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import axios from 'axios'
import { ScaleLoader } from 'react-spinners'
import { api_url } from '../../../../config/config';
import { getAlerts, deleteAlert } from '../../../../actions/alertActions'

class AlertList extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.getAlerts()
  }

  getTables(ads) {
    
    var tables = []
    
    ads.forEach((ad,k) => {
        
        tables.push(
            <div key={k}>
            
            <h4>{ad.title}</h4>
            <table className="table table-striped table-hover"><thead><tr>
                <th>Nom</th>
                <th>Type</th>
                <th>Recherche</th>
                <th>Modifier</th>
                <th>Supprimer</th>
                </tr>
            </thead>
            <tbody>
                {ad.alerts}
            </tbody>
            </table>
            </div>
        )
    })

    return tables
    
  }
  editAlert(id) {
    console.log(id)
  }

  deleteAlert(id) {
    console.log(id)
    this.props.deleteAlert(id)
  }

  render() {
    
    const { constants, errors, alerts } = this.props
    var ads =[]
  
    var loading = ''
    if(alerts.isLoading) {
      loading = <ScaleLoader className="text-center" color={"#BB3234"} />
    }
    if(constants.items && alerts.items.length >0) {
        
        constants.items.adTypes.forEach(adType => {
            var arr = {
                title: adType.name,
                alerts: []
            }
            alerts.items.forEach((alert,k) => {
                let type = null
                let typeName = "typesLA"
                if(alert.group == '1') typeName = "typesMP"
                type = constants.items[typeName].find(t=> t.id == alert.type)
                
                if(alert.group == adType.id) {
                    arr.alerts.push(<tr key={k}>
                        <td>{alert.name}</td>
                        <td>{type && type.name}</td>
                        <td>{alert.keywords}</td>
                        <td><Link className="btn btn-lqo btn-lqo-secondary" to={"/mes-alertes/"+alert.id}>Modifier</Link></td>
                        <td><button className="btn btn-lqo btn-lqo-primary" onClick={() => this.deleteAlert(alert.id)}>Supprimer</button></td>
                    </tr>)
                }
            });
            ads.push(arr)
        })
    }

    var table = this.getTables(ads)
    
    
    
    return (
      <div className="animated fadeIn">
        <h1>Mes alertes <Link style={{float:'right'}} className="btn btn-lqo btn-lqo-primary" to="/mes-alertes/create">Créer une nouvelle alerte</Link></h1>
        <div className="col-md-12 mx-auto text-center">
        {loading}
        </div>
        <div className="col-12 ">
            {table}
                
        </div>
      </div>
    );
  }
}

AlertList.propTypes = {
  constants: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  constants: state.constants,
  errors: state.errors,
  alerts: state.alerts
})

export default connect(mapStateToProps, { getAlerts, deleteAlert })(AlertList);