import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'

const initialState = {}
const middleware = [thunk]
let store

if(process.env.REACT_APP_STORE_MODE == 'production'){
    store = createStore(
        rootReducer, 
        initialState,
        compose(
            applyMiddleware(...middleware)
        )
    )
}else{
    store = createStore(
            rootReducer, 
            initialState,
            compose(
                applyMiddleware(...middleware),
                // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
            )
        )

}


export default store