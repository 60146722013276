import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_LEGALADS, GET_LEGALADS, GET_LEGALAD, GET_LATEST_LEGALADS} from './types'
import { api_url } from '../config/config'
import { DateTime } from 'luxon'

// Get latest legalads (homepage)
export const getLatestLegalAds = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads/latest')
        .then(res => {
            dispatch({type: GET_LATEST_LEGALADS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Search legalads
export const searchLegalAds = (searchData) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})

    //on traite les dates si jamais elles sont mal formatées
    if(searchData.dBeginPublished != "" && !DateTime.fromFormat(searchData.dBeginPublished, "yyyy-MM-dd").isValid){
        searchData.dBeginPublished = DateTime.fromFormat(searchData.dBeginPublished, "dd/MM/yyyy").toFormat('yyyy-MM-dd')
    }
    if(searchData.dEndPublished && searchData.dEndPublished != "" && !DateTime.fromFormat(searchData.dEndPublished, "yyyy-MM-dd").isValid){
        searchData.dEndPublished = DateTime.fromFormat(searchData.dEndPublished, "dd/MM/yyyy").toFormat('yyyy-MM-dd')
    }
    
    axios.post(api_url + 'legalads/search', searchData)
        .then(res => {
            dispatch({type: GET_LEGALADS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get all legalads
export const getLegalAds = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads')
        .then(res => {
            dispatch({type: GET_LEGALADS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get specific legalads
export const getLegalAd = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads/' + id)
        .then(res => {
            dispatch({type: GET_LEGALAD, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

