import React from 'react';
import { Route, Redirect, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import tools from '../../utils/tools';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, users, forceDisplay, ...rest }) => (
  
  <Route
    {...rest}
    render = {props =>
      auth.isAuthenticated === true ? (
        (tools.isValidContract(auth.user) || forceDisplay) ? (
          <Component {...props} />
        ):(
          <div className="alert alert-danger">
            Vous n'avez pas accès à cette fonctionnalité. Plusieurs raisons à cela:
            <ul>
              <li>Votre contrat n'est pas encore actif (en attente de paiement)</li>
              <li>Votre contrat expiré</li>
              <li>Votre compte a été suspendu</li>
            </ul>
            Pour avoir plus de précision veuillez prendre <Link to="/contact">contact</Link> avec un administrateur.
          </div>
        )
      ) : (
        <Redirect to={"/login?url_redirect="+props.location.pathname} />
        )
    }
  />
)

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(withRouter(PrivateRoute))
