import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Slider from '../../Components/Slider'
import LastLegal from '../../Components/LastLegal'
import LastMarket from '../../Components/LastMarket'

class Home extends Component {

  constructor(props) {
    super(props)
    this.state={}

  }

  render() {
    
    const {auth} = this.props
    var viewPublicMarket = <Link className="btn-lqo-primary py-1 px-2" to="/inscription/1"><i className="fas fa-caret-right"></i> S'abonner à la veille</Link>
    var viewLegalAd = <Link className="btn-lqo-primary py-1 px-2" to="/inscription/1"><i className="fas fa-caret-right"></i> S'abonner à la veille</Link>
    if(auth.isAuthenticated) {
      viewPublicMarket = <Link to="/marches-publics" className="btn-lqo-primary py-1 px-2">
        <i className="fas fa-caret-right"></i>
        Voir tous les marchés publics
      </Link>
      viewLegalAd = <Link to="/annonces-legales" className="btn-lqo-primary py-1 px-2">
        <i className="fas fa-caret-right"></i>
        Voir toutes les annonces légales
      </Link>
    }

    return (
      <div id="home" className="animated fadeIn" style={{marginTop: "-3rem"}}>

        <div style={{margin: "0 -15px"}}>
          <img className="" alt="" src="main_home.jpg" style={{width: "100%"}} />
        </div>

        <div className="row mt-5">
          <div className="col-12 col-lg-4">
            <div className="text-center">
              <img className="test" src="/img/loupe_blue.svg" alt="Veille" width="130" />
              <div className="img-sub-title"><span>VEILLE</span></div>
              <h4 className="mt-3">
                DES <Link className="text-blue-alt" to="/veille-marches-publics">MARCHÉS PUBLICS</Link>
                <br/> ET <Link className="text-blue-alt" to="/veille-annonces-legales">ANNONCES LÉGALES</Link><br/>
                 SUR UN SEUL ET MÊME OUTIL
              </h4>
              <p>
                Ne manquez plus aucune information, abonnez-vous à notre service en ligne et recevez, chaque jour,
                directement la sélection des annonces qui vous intéressent, dans votre boîte mail.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="text-center">
              <img className="test" src="/img/cle_usb_blue.svg" alt="Veille" width="130" />
              <div className="img-sub-title"><span><Link className="text-white" to="/signature-electronique">SIGNATURE ÉLECTRONIQUE</Link></span></div>
              <h4 className="mt-3">
                OUTIL INDISPENSABLE POUR RÉPONDRE AUX APPELS D'OFFRES DÉMATÉRIALISÉS
              </h4>
              <p>
                Obligatoire depuis le 1er octobre 2018, la signature électronique permet de valider la conformité d'un
                document et d'authentifier la signature. Le certificat CERTEUROPE ADVANCED CA V4 RGS**elDAS
                est compatible avec tous les marchés dématérialisés français et européens.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="text-center">
              <img className="test" src="/img/clic_blue.svg" alt="Veille" width="130" />
              <div className="img-sub-title"><span>WWW.OFFICIEL.RE</span></div>
              <h4 className="mt-3 mb-4"><Link to="/inscription/1" style={{color: "#627590"}}>INSCRIVEZ-VOUS<br/> DÈS MAINTENANT</Link></h4>
              <div style={{fontSize: "18px", fontWeight: "bold"}}>
                <div className="text-blue-alt">L'OFFICIEL</div>
                <div className="my-2">
                  <a style={{color: "#627590"}} href="mailto:contact@officiel.re">CONTACT@OFFICIEL.RE</a>
                </div>
                <a style={{color: "#627590"}} href="tel:0262976230">0262 97 62 30</a>
              </div>
            </div>
          </div>
        </div>

        {/* <Slider />

        <LastLegal viewLegalAd={viewLegalAd}/>
        <LastMarket viewPublicMarket={viewPublicMarket}/> */}

        {/* <div className="contentBox">
            <h2 className="elecSign">signature électronique</h2>
            <div className="p-3">
              <p className="font-weight-bold text-center">La signature électronique est un élément essentiel de la dématérialisation</p>
              <p>
                Vous souhaitez répondre par voie dématérialisée à des appels d'offres de marché publics ? Il permet
                de sécuriser tout type d'échanges dématérialisés.<br/>
                C'est une véritable carte d'identité électronique, le certificat est garantit.
              </p>
            </div>
            <div className="text-right">
              <Link className="btn-lqo-primary py-1 px-2" to="/"><i className="fas fa-caret-right"></i> Commander</Link>
            </div>
        </div> */}

      </div>
    )
  }
}

Home.propTypes = {
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { })(withRouter(Home));