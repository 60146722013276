import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getUser, updateUser, populateUserFromCompanyData, createUser } from '../../../../actions/userActions'
import { createContract } from '../../../../actions/contractActions'
import UserDetail from '../../../Components/UserDetail';
import ContractDetail from '../../../Components/ContractDetail';
import UserContract from '../../../Components/UserContract';

class UserAccount extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user: {
        id: null,
        login: "",
        email: "",
        gender: "1",
        lastname: "",
        firstname: "",
        company: "",
        address: "",
        siren: "",
        postalCode: "",
        phone: "",
        city: "",
        status: "",
        currentContract: {},
      },
      contract: {
        offer: "",
        paymentType: "",
        promoToken: null,
        alertOpt: false,
      },
      
    }

    this.changeInput = this.changeInput.bind(this)
    this.handleOfferChange = this.handleOfferChange.bind(this)
    this.handleAlertOfferChange = this.handleAlertOfferChange.bind(this)
    this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.createUser = this.createUser.bind(this)
    this.createContract = this.createContract.bind(this)
  }

  componentDidMount()
  {
    if(this.props.auth.isAuthenticated){
      this.props.getUser(this.props.auth.user.id)
    }else{
      if(this.props.companies.selected){
        this.props.populateUserFromCompanyData(this.props.companies.selected)
        if(this.props.companies.selected.promoToken) {
          var state = {...this.state}
          state.contract.promoToken = this.props.companies.selected.promoToken
          this.setState({state})
        }
      }
    }
  }

  componentWillReceiveProps(nextProp)
  {
    if(nextProp.users.item){
      var user = {... this.state.user}
      for (let k in this.state.user) {
        if(nextProp.users.item[k])user[k] =  nextProp.users.item[k]
      }
      this.setState({user: user})
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    if(name == 'phone') {
      value = value.replace(/\s/g, '')
    }
    var user = {... this.state.user}
    user[name] = value
    this.setState({user})
  }

  handleOfferChange(offer) {
    var contract = {... this.state.contract}
    contract.offer = offer
    contract.alertOpt = false
    this.setState({contract})
  }

  handleAlertOfferChange(offer) {
    var contract = {... this.state.contract}
    contract.offer = offer
    contract.alertOpt = true
    this.setState({contract})
  }

  handlePaymentTypeChange(paymentType) {
    var contract = {... this.state.contract}
    contract.paymentType = paymentType
    this.setState({contract})
  }

  updateUser() {
    this.props.updateUser(this.state.user)
  }

  createUser() {
    this.props.createUser(this.state.user)
  }

  createContract() {
    var {contract} = this.state
    
    //si l'utilisateur existe, on créé directement le contrat
    if(this.state.user.id){
      contract.user = this.state.user.id
      this.createContractAfterVerification(contract)

    //si non, on doit d'abord créer l'utilisateur et ensuite le contrat
    }else{
      this.props
        .createUser(this.state.user)
        .then(u => {
          contract.user = u.id
          this.createContractAfterVerification(contract)
        })
    }
  }

  createContractAfterVerification(contract)
  {
    this.props
        .createContract(contract)
        .then(r => {
          if(r.paymentType == 1){
            window.location = "/paiement/" + r.id
          }else{
            window.location = "/paiement/2/" + r.id + "/return/success"
          }
        })
  }


  render() {
    const {user, contract} = this.state
    
    return (
      <div className="animated fadeIn">
        <h1>{user.id ? "Mon compte utilisateur" : "Créer mon compte utilisateur — étape 2 sur 2"}</h1>

        <UserDetail 
          user={user} 
          changeInput={this.changeInput} 
          updateUser={this.updateUser}
           /> 
           
        <h1>Mon abonnement</h1>
        <ContractDetail 
          user={user} 
          contract={contract} 
          handleOfferChange={this.handleOfferChange} 
          handleAlertOfferChange={this.handleAlertOfferChange} 
          handlePaymentTypeChange={this.handlePaymentTypeChange} 
          createContract={this.createContract} 
          />

        {user.id && <UserContract />}

      </div>
    );
  }
}

UserAccount.propTypes = {
  getUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  populateUserFromCompanyData: PropTypes.func.isRequired,
  createContract: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
  companies: state.companies,
  errors: state.errors,
})

export default connect(mapStateToProps, { getUser, createUser, updateUser, populateUserFromCompanyData, createContract })(UserAccount);