import React, { Component } from 'react';
import PropTypes from 'prop-types';


class DefaultFooter extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          
        </div>
      </footer>
    );
  }
}

export default DefaultFooter;
