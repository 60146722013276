import React from 'react';

const AboutUs  = () => (
  <div className="animated fadeIn">
    <div className="mb-3">
      <h3>Notre HISTOIRE : L’Officiel une marque Le Quotidien.</h3>
      <p>
        <strong>Fondé en 1976</strong>, leader dans la presse écrite régionale, seul titre labellisé <strong>*OJD / ACPM</strong>, Le Quotidien a élargi sa communication depuis 2005 sur les marchés 
        publics en proposant son support d’information spécialisé : <strong>l’Officiel.re</strong>.
      </p>
      <h3 className="mt-5">Nos SERVICES : Un ensemble de solutions qui facilite l’accès aux marchés publics.</h3>
      <p>
        <strong>Un site internet spécialisé dans la veille permanente</strong> des marchés publics et des annonces 
        légales sur l’ensemble du département de La Réunion et de Mayotte.
      </p>
      <p>
        Chaque jour, sont recensées toutes les annonces publiées dans la presse et sur les sites dédiés (BOMP : le bulletin officiel des marchés publics, achatpublic.com, ...)<br/>
        Si vous êtes abonnés à l’Officiel, vous recevez une alerte e-mail quotidiennement avec le résultat de votre 
        veille préalablement paramétrée.<br/>
        L’Officiel de La Réunion propose des abonnements de 3,6 et 12 mois.
      </p>
      <p>
        L’Officiel.re est également <strong>fournisseur agréé de la *signature électronique</strong> CERTEUROPE ADVANCED CA V4 RGD** eIDAS, 
        l’outil indispensable pour répondre aux appels d’offres dématérialisés.<br/>
        Commande / livraison chez vous / aide à l’installation et formation à l’utilisation par notre équipe.<br/>
        La signature électronique est valable 3 ans.
      </p>
      <div className="text-asterisk">
        *L’OJD désormais appelé L’ACPM a pour mission la mesure de l’audience de la presse et la certification du dénombrement des médias.
      </div>

      

      <h3 className="mt-5">Nos FORCES : Conseil, proximité, réactivité !</h3>
      <div className="py-2">
        <strong>Conseil</strong> : Une problématique ? Nous vous conseillons sur le service approprié à votre besoin.
      </div>
      <div className="py-2">
        <strong>Proximité</strong> : Notre équipe vous accompagne à chaque étape.
      </div>
      <div className="py-2">
        <strong>Réactivité</strong> : Une veille quotidienne pour ne rater aucune opportunité, une livraison clé en main 
        chez vous pour ne pas perdre de temps dans l’administratif.
      </div>
    </div>

    <h3 className="font-weight-bold text-center mt-5">
      <a href="/">www.officiel.re</a> vous donne une longueur d'avance !
    </h3>

    
    <div className="text-center mb-3">
    <a href="/">
        <img src="/img/logo_rounded.svg" alt="Logo L'Officiel" width="200" />     
    </a>
    </div>
  </div>
)

export default AboutUs;
