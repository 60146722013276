import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getLegalAd } from '../../../actions/legalAdActions'
import { ScaleLoader } from 'react-spinners'
import { DateTime } from 'luxon'
import { api_url, image_url } from '../../../config/config'
import storageHelper from '../../../utils/storageHelper'

class LegalAd extends Component {

  constructor(props) {
    super(props)

    this.getLegalAdPDF = this.getLegalAdPDF.bind(this)
  }

  getLegalAdPDF(id)
  {
    window.open(api_url + 'pdf/legalads/' + id + '/' + localStorage.getItem('jwtToken').replace('Bearer ', ''))
  }

  componentDidMount()
  {
    this.props.getLegalAd(this.props.match.params.id)
  }

  render() {
    const {constants} = this.props
    const legalAd = this.props.legalAds.item
    let type = null
    if(constants.items && legalAd){
      type = constants.items.typesLA.find(cat=> cat.id == legalAd.type)
    }
    
    return (
      <div className="animated fadeIn">
        <h1>{legalAd ? legalAd.title : "chargement en cours"}</h1>

        {!legalAd || !constants.items ? (
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
        ) : (
          <div>
            <div className="row mb-5 px-3">
    
              <div className="col-md-6 preview">
                { legalAd.image != "" && 
                <div className="form-group row">
                  <div className="col-sm-12 text-center mb-3">
                    
                    <img 
                      style={{maxHeight:100}}
                      src={storageHelper(legalAd)} />
                  
                  </div>
                </div>
                }
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Référence</label>
                    <div className="col-sm-7">
                        {legalAd.reference}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="type" className="col-sm-5 col-form-label">Type</label>
                    <div className="col-sm-7">
                        {type && type.name}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Forme juridique</label>
                    <div className="col-sm-7">
                      {(constants.items.legalForms && legalAd.legalForm) ? constants.items.legalForms.find(leg=> leg.id == legalAd.legalForm).name : ""}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Titre</label>
                    <div className="col-sm-7">
                      {legalAd.title}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Source</label>
                    <div className="col-sm-7">
                      {(constants.items.sources && legalAd.source) ? constants.items.sources.find(sour=> sour.id == legalAd.source).name : ""}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Parution journal</label>
                    <div className="col-sm-7">
                        {DateTime.fromISO(legalAd.newspaperPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Parution web</label>
                    <div className="col-sm-7">
                        {DateTime.fromISO(legalAd.webPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                </div>
    
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-5 col-form-label">Liquidateur</label>
                    <div className="col-sm-7">
                        {legalAd.liquidator}
                    </div>
                </div>
    
              </div>
              <div className="col-md-6 content-block">
                <div className="form-group row">                
                    <div className="col-sm-12">
                      <div dangerouslySetInnerHTML={{ __html: legalAd.content}} />                   
                    </div>
                </div>
              </div>
    
            </div>

            <div className="text-center">
              <Link to="/annonces-legales" className="btn btn-lqo btn-lqo-primary mb-3">RETOUR AUX RESULTATS</Link><br/>
              <button className="btn btn-lqo btn-lqo-secondary" onClick={() => {this.getLegalAdPDF(legalAd.id)}}>TELECHARGER EN PDF</button>
            </div>
          </div>
        )}

      </div>
    );
  }
}

LegalAd.propTypes = {
  getLegalAd: PropTypes.func.isRequired,
  legalAds: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  legalAds: state.legalAds,
  errors: state.errors,
  constants: state.constants,
})

export default connect(mapStateToProps, { getLegalAd })(LegalAd);