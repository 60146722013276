import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import store from './store'
import { Provider } from 'react-redux'
// Components
import DefaultFooter from './views/Components/Layout/DefaultFooter';
import DefaultHeader from './views/Components/Layout/DefaultHeader';
import Error from './views/Components/Error';
import PrivateRoute from './views/Components/PrivateRoute';
import Menu from './views/Components/Layout/Menu';
// Pages
import { 
  Login, 
  SearchCompany, 
  Page404, 
  AboutUs, 
  ElectronicSign, 
  PublicMarketWaking, 
  LegalAdWaking, 
  LostPassword, 
  Activation, 
  ResetPassword,
  Contact
 } from './views/Pages';
import Home from './views/Pages/Public/Home';
import UserAccount from './views/Pages/Private/User/UserAccount';
import PublicMarkets from './views/Pages/Private/PublicMarkets'
import PublicMarket from './views/Pages/Private/PublicMarket'
import LatestAdditions from './views/Pages/Private/LatestAdditions'
import LegalAds from './views/Pages/Private/LegalAds'
import LegalAd from './views/Pages/Private/LegalAd'
import PromoCode from './views/Pages/Private/PromoCode'
import AlertDetail from './views/Pages/Private/Alert/AlertDetail';
import AlertList from './views/Pages/Private/Alert/AlertList';
import AdFooter from './views/Components/AdFooter'
//Redux
import jwt_decode from 'jwt-decode'
import { SET_CURRENT_USER } from './actions/types'
import { logoutUser } from './actions/authActions'
import setAuthToken from './utils/setAuthToken'
import ReturnPayment from './views/Pages/Public/ReturnPayment';
import PaymentCB from './views/Pages/Public/PaymentCB';
import { getConstants } from './actions/constantActions'

//FIXES REACT-BOOTSTRAP-TABLE
//on importe ce css sinon les head ne sont pas aligner avec le content body
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'

// Check for token user
if(localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken)
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken)
  // Set user and isAuthenticated
  store.dispatch({type: SET_CURRENT_USER, payload: decoded})

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    
    store.dispatch(logoutUser());
    // Clear current Profile
    // store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/login';
  }
}

//chargement des constantes
store.dispatch(getConstants())

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="animated fadeIn">
            <DefaultHeader />
            <main role="main" className="container my-5">
              <div className="row">
                <div className="col">
                  <Error />
                  <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/qui-sommes-nous' component={AboutUs} />
                    <Route exact path='/signature-electronique' component={ElectronicSign} />
                    <Route exact path='/veille-marches-publics' component={PublicMarketWaking} />
                    <Route exact path='/veille-annonces-legales' component={LegalAdWaking} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/mot-de-passe-perdu' component={LostPassword} />
                    <Route exact path='/inscription/1/:token' component={SearchCompany} />
                    <Route exact path='/inscription/1' component={SearchCompany} />
                    <Route exact path='/inscription/2' component={UserAccount} />
                    <Route exact path='/paiement/:type/:idContract/return/:return' component={ReturnPayment} />
                    <Route exact path='/paiement/:id' component={PaymentCB} />
                    <Route exact path='/activation/:token' component={Activation} />
                    <Route exact path='/reset_password/:token' component={ResetPassword} />
                    <Route exact path='/contact' component={Contact} />
                    
                  </Switch>
                  <Switch>
                    <PrivateRoute exact path='/mon-compte' component={UserAccount} forceDisplay={true} />
                    <PrivateRoute exact path='/mes-alertes/:id' component={AlertDetail} />
                    <PrivateRoute exact path='/mes-alertes' component={AlertList} />
                    <PrivateRoute exact path='/derniers-ajouts' component={LatestAdditions} />
                    <PrivateRoute exact path='/marches-publics' component={PublicMarkets} />
                    <PrivateRoute exact path='/marches-publics/:id' component={PublicMarket} />
                    <PrivateRoute exact path='/annonces-legales' component={LegalAds} />
                    <PrivateRoute exact path='/annonces-legales/:id' component={LegalAd} />
                    {/* <PrivateRoute exact path='/offrir-coupon' component={PromoCode} /> */}
                  </Switch>
                </div>
                <Menu />
              </div>
              {/* <AdFooter /> */}
            </main>
            {/* <DefaultFooter /> */}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;