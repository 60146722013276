import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {logoutUser} from '../../../actions/authActions';
import AdSide from '../AdSide'

const Menu = ({ auth, logoutUser }) => (
  <div id="containerRightSide" className={auth.isAuthenticated ? "col-12 col-md-4 col-lg-3" : ""}>
    { !auth.isAuthenticated ? (
      <div>
        
      </div>
    ) : (
      <div className="menu menu-red" id="menuConnected">
        <div className="dashTitle mb-3">MENU CLIENT</div>
        <ul className="list-unstyled m-auto">
          <li>
            <Link to="/annonces-legales" className="linkMenu text-red">
              <i className="fas fa-caret-right"></i>
              Annonces légales
            </Link>
          </li>
          <li>
            <Link to="/marches-publics" className="linkMenu text-red">
              <i className="fas fa-caret-right"></i>
              Marchés publics
            </Link>
          </li>
          {
            auth.user.currentContract && auth.user.currentContract.alertOpt == true &&
            <li>
              <Link to="/mes-alertes" className="linkMenu text-red">
                <i className="fas fa-caret-right"></i>
                Mes alertes
              </Link>
            </li>
          }
          {/* <li>
            <Link to="/offrir-coupon" className="linkMenu text-red">
              <i className="fas fa-caret-right"></i>
              Offrir un coupon
            </Link>
          </li> */}
        </ul>
        <div className="row" style={{fontSize: 14}}>
          <div className="col-6 col-md-12 col-lg-6"><Link to="/mon-compte" className="text-white">MON COMPTE</Link></div>
          <div className="col-6 col-md-12 col-lg-6"><a href="#" className="text-white" onClick={() => {logoutUser()}}>DECONNECTER</a></div>
        </div>
      </div>
    )}
    
    <div>
      
    </div>
    {/* <AdSide /> */}
  </div>
  
)

Menu.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, {logoutUser})(Menu)
