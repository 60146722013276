import React from 'react'
import { Link } from 'react-router-dom'

const LegalAdWaking  = () => (
  <div className="animated fadeIn" id="public-market">
    <div className="mb-3">
      <h2>Votre veille de marchés publics et d'<strong>annonces légales</strong> sur 
         <span className="text-blue-alt font-weight-bold"> un seul et même outil</span>
      </h2>

      <div className="container my-5 p-0">

        <div className="mb-3">
          <div className="frame-blue text-center text-white rounded">
              Ne manquez plus aucune information, abonnez-vous à notre service en ligne
              et chaque jour, recevez directement la sélection des annonces qui vous 
              intéressent !
          </div>
        </div>

        <div className="mb-3">
          <div className="text-center text-blue" style={{border: "5px #BFD008 solid"}}>
            <div className="bg-green text-white p-3 font-weight-bold"
              style={{lineHeight: "23px", fontSize: 21}}>
              <div className="mb-2"><img width="100" src="/img/bulle_dialog.svg" alt="Bulle de dialogue" /></div>
              COMMENT ÇA <br/>MARCHE ?
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 my-2">
                  <div className="num-point">1</div>
                  Inscrivez-vous sur <br/>
                  <Link to="/inscription" className="font-weight-bold font-18 text-blue">www.officiel.re</Link>
                </div>
                <div className="col-12 col-lg-4 my-2">
                  <div className="num-point">2</div>
                  <div className="font-18 font-weight-bold">Définissez vos critères</div>
                  de sélection pour les marchés publics et/ou les annonces légales
                </div>
                <div className="col-12 col-lg-4 my-2">
                  <div className="num-point">3</div>
                  <div className="font-18 font-weight-bold">Recevez par e-mail votre sélection</div>
                  tous les jours à la même heure et soyez au courant avant vos concurrents
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mb-3">
          
          <div className="text-blue">
            <div className="row">

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <div className="text-center"><img src="/img/ampoule.svg" alt="Exhaustivité" width="100" /></div>
                <div className="bg-blue p-2 text-white text-center font-weight-bold title-icon mb-2">EXHAUSTIVITÉ</div>
                <div>Pour être sûr de ne rater aucun appel d'offre public de l'Océan Indien</div>
                <div className="text-blue-alt font-weight-bold">+ 100 ANNONCES QUOTIDIENNES</div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <div className="text-center"><img src="/img/montre.svg" alt="Gain de temps" width="100" /></div>
                <div className="bg-blue p-2 text-white text-center font-weight-bold title-icon mb-2">GAIN DE TEMPS</div>
                <div>Recevez les annonces qui vous intéressent tous les jours directement dans votre boîte mail</div>
                <div className="text-blue-alt font-weight-bold">+ 500 ALERTES PAR JOUR</div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <div className="text-center"><img src="/img/loupe.svg" alt="Surveillance" width="100" /></div>
                <div className="bg-blue p-2 text-white text-center font-weight-bold title-icon mb-2">SURVEILLANCE</div>
                <div>Suivez les changements chez vos concurrents et/ou clients, grâce à la veille sur les annonces légales</div>
                <div className="text-blue-alt font-weight-bold">+ 500 ALERTES PAR JOUR</div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <div className="text-center"><img src="/img/boucle_24h.svg" alt="Accessibilité" width="100" /></div>
                <div className="bg-blue p-2 text-white text-center font-weight-bold title-icon mb-2">ACCESSIBILITÉ</div>
                <div className="text-blue-alt font-weight-bold">L'INFO 24H/24 & 7JOURS/7</div>
                <div>Sur tous les supports, où que vous soyez</div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <div className="text-center"><img src="/img/tirelire.svg" alt="Economique" width="100" /></div>
                <div className="bg-blue p-2 text-white text-center font-weight-bold title-icon mb-2">ÉCONOMIQUE</div>
                <div>100% dématérialisé, nos forfaits commencent à 20€/mois</div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3 align-self-center">
                <Link to="/inscription/1" className="btn btn-lqo-primary p-3 font-18" style={{width: "100%", whiteSpace: "normal"}}>
                  Inscrivez-vous dès maintenant
                </Link>
              </div>
              
            </div>
          </div>

        </div>

        <hr/>
        <div className="mb-3">
          <h2 className="p-3 font-weight-bold">NOS TARIFS</h2>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="text-center prices">
                <div className="bg-blue-alt p-3 font-18 font-weight-bold">
                  <div className="text-white">1er Abonnement à la veille</div>
                  <div className="text-blue" style={{lineHeight: "17px"}}>Option alerte emails <br/>OFFERTE la 1re année</div>
                </div>
                <div className="bg-blue text-white">
                  <ul className="list-unstyled">
                    <li>3 mois : 70€ TTC</li>
                    <li>6 mois : 130€ TTC</li>
                    <li>1 an : 240€ TTC</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="text-center prices">
                <div className="bg-blue-alt p-3 font-18 font-weight-bold">
                  <div className="text-white">Renouvellement à la veille</div>
                  <div className="text-blue" style={{lineHeight: "17px"}}>Option alerte emails incluse<br/><br/></div>
                </div>
                <div className="bg-blue text-white">
                  <ul className="list-unstyled">
                    <li>3 mois : 102,55€ TTC</li>
                    <li>6 mois : 195,10€ TTC</li>
                    <li>1 an : 370,20€ TTC</li>
                  </ul>
                </div>
              </div>
            </div>
          
            <div className="col-12">
              <Link to="/inscription/1" className="btn btn-lqo-primary p-3 font-18" style={{width: "100%", whiteSpace: "normal"}}>
                Inscrivez-vous dès maintenant
              </Link>
            </div>

          </div>


        </div>
      </div>
    
    </div>
  </div>
)

export default LegalAdWaking;
