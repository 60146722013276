import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios'
import { ScaleLoader } from 'react-spinners'
import { BarLoader } from 'react-spinners';
import {api_url} from '../../../config/config'

class Contact extends Component {

  constructor(props) {
    super(props)
    this.state= {
      isLoading: false,
      errors: {},
      isSent: false,
      contact: {
          email: '',
          lastname: '',
          firstname: '',
          company: '',
          phone: '',
          message: ''
      }
    }

    this.inputChange = this.inputChange.bind(this)
    this.addContact = this.addContact.bind(this)
  }

  inputChange(e) {
      console.log(e.target.name)
      var contact = this.state.contact
      contact[e.target.name] = e.target.value
      console.log(contact)
      this.setState({contact})
  }

  addContact() {
    this.setState({isLoading:true, errors:{}})
    console.log(this.state.contact)
    axios.post(api_url+'contact', this.state.contact)
    .then(res => {
        console.log(res)
        this.setState({isLoading:false, isSent:true})
    })
    .catch(err => {
        console.log(err.response.data)
        this.setState({
            errors: err.response.data,
            isLoading: false
        })
    })
  }

  render() {
    var content = <div className="col-12 text-center">
        <h4 style={{color:'green'}}>
        <i class="fas fa-check-double"></i> Merci, votre message à bien été envoyé.
        </h4>
    </div>
    if(!this.state.isSent) {

        content = <form className="col-6 m-auto">
            <div className="form-group">
                <label htmlFor="email">Votre email</label>
                <input onChange={this.inputChange} name="email" type="email" id="email" className="form-control"  placeholder="Email"
                value={this.state.email}/>
                <small className="form-text text-danger">{this.state.errors.email}</small>
            </div>
            <div className="form-group">
                <label htmlFor="lastname">Nom</label>
                <input onChange={this.inputChange} name="lastname" type="text" className="form-control" id="lastname" placeholder="Nom"
                value={this.state.lastname}/>
                <small className="form-text text-danger">{this.state.errors.lastname}</small>
            </div>
            <div className="form-group">
                <label htmlFor="firstname">Prénom (facultatif)</label>
                <input onChange={this.inputChange} name="firstname" type="text" className="form-control" id="firstname" placeholder="Prénom"
                value={this.state.firstname}/>
                <small className="form-text text-danger">{this.state.errors.firstname}</small>
            </div>
            <div className="form-group">
                <label htmlFor="company">Société</label>
                <input onChange={this.inputChange} name="company" type="text" className="form-control" id="company" placeholder="Société"
                value={this.state.company}/>
                <small className="form-text text-danger">{this.state.errors.company}</small>
            </div>
            <div className="form-group">
                <label htmlFor="phone">Téléphone</label>
                <input onChange={this.inputChange} name="phone" type="tel" className="form-control" id="phone" placeholder="Téléphone"
                value={this.state.phone}/>
                <small className="form-text text-danger">{this.state.errors.phone}</small>
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea onChange={this.inputChange} name="message" rows="10" className="form-control" id="message" placeholder="Entrez votre message">{this.state.message}</textarea>
                <small className="form-text text-danger">{this.state.errors.message}</small>
            </div>
            {
                (this.state.isLoading) ? (
                    <BarLoader
                    widthUnit={"%"}
                    width={100}
                    color={'#bb3234'}
                    />
                )

                :
                (<button type="button" onClick={this.addContact} className="btn btn-primary">Envoyer</button>)
            }
            
            <div className="mt-5">
                <p>
                    <strong>Adresse : </strong><br/>
                    1 rue Lislet Geoffroy<br/>
                    97490 Ste Clotilde<br/>
                    Tél : <a href="tel:026-297-5230">0262 97 52 30</a><br/>
                    Mail général : <a href="mailto:vibar@officiel.re">contact@officiel.re</a>
                </p>
                <div className="text-center">
                    <img src="/img/logo_rounded.svg" alt="Logo L'Officiel" width="200" /> 
                
                </div>
            </div>
        </form>
    }
    
    

    return (
        <div>
            <h1>
                Nous contacter
            </h1>
            {content}
        </div>
    )
  }

}

export default Contact
