import axios from 'axios'
import { GET_ERRORS, CLEAR_ERRORS, LOAD_COMPANIES, GET_COMPANIES, SELECT_COMPANY} from './types'
import { api_url } from '../config/config'

// Search companies
export const searchCompanies = (search) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_COMPANIES, payload: true})
    
    axios.get(api_url + 'companies/search/' + search)
        .then(res => {
            dispatch({type: GET_COMPANIES, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_COMPANIES, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Select company
export const selectCompany = (company) => dispatch => {
    dispatch({type: SELECT_COMPANY, payload: company})
}