import { LOAD_ALERTS, ADD_ALERT, GET_ALERTS, GET_ALERT, DELETE_ALERT, UPDATE_ALERT } from '../actions/types'

const initialState = {
    items: [],
    item : null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_ALERTS:
            return {
                ...state,
                item: null,
                isLoading: action.payload,
                isSaved:false
            }
            break;
        case ADD_ALERT:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved:true
            }
            break;
        case GET_ALERTS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false,
                isSaved:false
            }
            break;
        case GET_ALERT:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved:false
            }
            break;
        case UPDATE_ALERT:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved:true
            }
            break;
        case DELETE_ALERT:
            return {
                ...state,
                items: state.items.filter(i => i.id != action.payload),
                item: null,
                isLoading: false,
                isSaved:false
            }
            break;
        
    
        default:
            return state
    }
}