export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const DELETE_CURRENT_USER = 'DELETE_CURRENT_USER'
export const POPULATE_CURRENT_USER = 'POPULATE_CURRENT_USER'

export const LOAD_COMPANIES = 'LOAD_COMPANIES'
export const GET_COMPANIES = 'GET_COMPANIES'
export const SELECT_COMPANY = 'SELECT_COMPANY'

export const LOAD_CONTRACTS = 'LOAD_CONTRACTS'
export const GET_CONTRACTS = 'GET_CONTRACTS'
export const CREATE_CONTRACT = 'CREATE_CONTRACT'

export const LOAD_LEGALADS = 'LOAD_LEGALADS'
export const GET_LATEST_LEGALADS = 'GET_LATEST_LEGALADS'
export const GET_LEGALADS = 'GET_LEGALADS'
export const GET_LEGALAD = 'GET_LEGALAD'

export const LOAD_PUBLICMARKETS = 'LOAD_PUBLICMARKETS'
export const GET_LATEST_PUBLICMARKETS = 'GET_LATEST_PUBLICMARKETS'
export const GET_PUBLICMARKETS = 'GET_PUBLICMARKETS'
export const GET_PUBLICMARKET = 'GET_PUBLICMARKET'

export const LOAD_CONSTANTS = 'LOAD_CONSTANTS'
export const GET_CONSTANTS = 'GET_CONSTANTS'

export const LOAD_ALERTS = 'LOAD_ALERTS'
export const ADD_ALERT = 'ADD_ALERT'
export const GET_ALERTS = 'GET_ALERTS'
export const GET_ALERT = 'GET_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'
export const UPDATE_ALERT = 'UPDATE_ALERT'

export const LOAD_PROMO_TOKEN = 'LOAD_PROMO_TOKEN'
export const CHECK_PROMO_TOKEN = 'CHECK_PROMO_TOKEN'