import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import tools from '../../../utils/tools'
import { ScaleLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios';
import { searchLegalAds } from '../../../actions/legalAdActions'
import { getLatestPublicMarkets } from '../../../actions/publicMarketActions'
import { api_url } from '../../../config/config'
import { DateTime } from 'luxon'

class LatestAdditions extends Component {

  constructor(props) {
    super(props)
    this.state={
      publicMarkets: {},
      legalAds: []
    }
    
    this.dateBegin = DateTime.local().toFormat('yyyy-MM-dd')
    // this.dateEnd = DateTime.local().toISODate()

    this.getPublicMarkets = this.getPublicMarkets.bind(this)
    // this.getLegalAds = this.getLegalAds.bind(this)
  }

  componentDidMount() {
    this.props.getLatestPublicMarkets()
    this.props.searchLegalAds({dBeginPublished: this.dateBegin, isFullWord: false})
  }

  getPublicMarkets() {
    
    axios.get(api_url+'publicmarkets/latest/additions')
          .then(res => {
            this.setState({
              publicMarkets: res.data
            })
          })
          .catch(err => {
            console.log(err.response.data)
          })
  }

  // getLegalAds() {
  //   axios.post(api_url+'legalads/search', {dBeginPublished: this.dateBegin, isFullWord: false})
  //         .then(res => {
  //           this.setState({
  //             legalAds: res.data
  //           })
  //         })
  //         .catch(err => {
  //           console.log(err.response.data)
  //         })
  // }

  formatPublicMarketCategories(categoriesMP, pm){
    var cat = []
    categoriesMP.forEach((c) => {
      if(pm.categories.find(x => c.id.toString() == x)){
        cat.push(c.name)
      }
    })
    return cat.join(',')
  }

  formatEmittor(emittor){
    const {constants} = this.props
    if(constants.items && emittor){
      let find = constants.items.emittors.find(c => c.id == emittor)
      return find ? find.name : ""
    }else{
      return ""
    }
  }

  formatSource(source){
    const {constants} = this.props
    if(constants.items && source){
      let find = constants.items.sources.find(c => c.id == source)
      return find ? find.name : ""
    }else{
      return ""
    }
  }
  
  render() {
    const {constants, legalAds, publicMarkets} = this.props
    var legalAdsTable = []
      var publicMarketsTable = {
        s: [],
        i: [],
        a: []
      }
    if(constants.items && legalAds.items && publicMarkets.latest) {

      legalAds.items.forEach((legalad, k) => {
        legalAdsTable.push(
          <tr key={k}>
            <td>{legalad.reference}</td>
            <td>{constants.items.typesLA.find(c => c.id == legalad.type).name}</td>
            <td>{legalad.title}</td>
            <td>{this.formatSource(legalad.source)}</td>
            <td><Link to={"annonces-legales/"+legalad.id}>VOIR LA FICHE</Link></td>
          </tr>
        )
      })

      for(var key in publicMarkets.latest) {
        // var publicMarkets = publicMarkets.latest[key]
        publicMarkets.latest[key].forEach((publicMarket, k) => {
          publicMarketsTable[key].push(
            <tr key={k}>
              <td>{publicMarket.reference}</td>
              <td>{this.formatPublicMarketCategories(constants.items.categoriesMP, publicMarket)}</td>
              <td>{constants.items.typesMP.find(c => c.id == publicMarket.type).name}</td>
              <td>{this.formatEmittor(publicMarket.emittor)}</td>
              <td>{this.formatSource(publicMarket.source)}</td>
              <td>{publicMarket.title}</td>
              <td>{constants.items.locations.find(c => c.id == publicMarket.location).name}</td>
              {publicMarket.type != 6 && <td>{DateTime.fromISO(publicMarket.expiredAt).toLocaleString(DateTime.DATE_SHORT)}</td>}
              <td><Link to={"marches-publics/"+publicMarket.id}>VOIR LA FICHE</Link></td>
            </tr>
          )
        })
      }
      
    }
    

    return (
      <div className="animated fadeIn">
        <h1>Les derniers ajouts</h1>

        <h3 style={{textAlign:'center'}}>MARCHÉS PUBLICS</h3>
        
        {publicMarkets.isLoading ?
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
          :
          <div>
            <h5><strong>Inférieurs à 90 000 €</strong></h5>
            <table className="table" style={{marginBottom:20}}>
              <thead>
                <tr>
                  <th>Ref.</th>
                  <th>Types de marché</th>
                  <th>Type</th>
                  <th>Emetteur</th>
                  <th>Source</th>
                  <th>Objet</th>
                  <th>Lieu</th>
                  <th>Date limite</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {publicMarketsTable.i}
              </tbody>
            </table>

            <h5><strong>Supérieurs à 90 000 €</strong></h5>
            <table className="table" style={{marginBottom:20}}>
              <thead>
                <tr>
                  <th>Ref.</th>
                  <th>Types de marché</th>
                  <th>Type</th>
                  <th>Emetteur</th>
                  <th>Source</th>
                  <th>Objet</th>
                  <th>Lieu</th>
                  <th>Date limite</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {publicMarketsTable.s}
              </tbody>
            </table>

            <h5><strong>Avis d'attribution</strong></h5>
            <table className="table" style={{marginBottom:20}}>
              <thead>
                <tr>
                  <th>Ref.</th>
                  <th>Types de marché</th>
                  <th>Type</th>
                  <th>Emetteur</th>
                  <th>Source</th>
                  <th>Objet</th>
                  <th>Lieu</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {publicMarketsTable.a}
              </tbody>
            </table>
          </div>
        }

        <h3 style={{textAlign:'center', marginTop:40}}>ANNONCES LÉGALES</h3>

        {legalAds.isLoading ?
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
          :
          <table className="table" style={{marginBottom:20}}>
            <thead>
              <tr>
                <th>Ref.</th>
                <th>Type</th>
                <th>Objet</th>
                <th>Source</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {legalAdsTable}
            </tbody>
          </table>
        }
        
      </div>
    );
  }
}

LatestAdditions.propTypes = {
  constants: PropTypes.object.isRequired,
  legalAds: PropTypes.object.isRequired,
  publicMarkets: PropTypes.object.isRequired,
  searchLegalAds: PropTypes.func.isRequired,
  getLatestPublicMarkets: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  constants: state.constants,
  legalAds: state.legalAds,
  publicMarkets: state.publicMarkets,
})

export default connect(mapStateToProps, {searchLegalAds, getLatestPublicMarkets})(LatestAdditions);
