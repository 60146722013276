import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_CURRENT_USER, GET_CURRENT_USER, SET_CURRENT_USER, POPULATE_CURRENT_USER} from './types'
import { api_url } from '../config/config'

// Get specific user
export const getUser = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    // dispatch({type: LOAD_CURRENT_USER, payload: true})
    
    axios.get(api_url + 'users/' + id)
        .then(res => {
            dispatch({type: GET_CURRENT_USER, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Create user
export const createUser = (data) => dispatch => {

    return new Promise((resolve, reject) => {
        dispatch({type: CLEAR_ERRORS, payload: {}})
        dispatch({type: LOAD_CURRENT_USER, payload: true})
        
        data.gender = data.gender.toString()
        axios.post(api_url + 'users', data)
            .then(res => {
                dispatch({type: LOAD_CURRENT_USER, payload: false})
                resolve(res.data)
            })
            .catch(err => {
                dispatch({type: LOAD_CURRENT_USER, payload: false})
                dispatch({type: GET_ERRORS, payload: err.response.data})
                reject(err)
            })
    })
}

// Update specific user
export const updateUser = (data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CURRENT_USER, payload: true})
    
    data.gender = data.gender.toString()
    axios.patch(api_url + 'users/' + data.id, data)
        .then(res => {
            dispatch({type: SET_CURRENT_USER, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update password specific user
export const changePasswordUser = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CURRENT_USER, payload: true})
    
    axios.patch(api_url + 'users/' + id + '/password', data)
        .then(res => {
            dispatch({type: SET_CURRENT_USER, payload: res.data})
            //on close la modal ici
            document.getElementById('closeModalChangePassword').click()
        })
        .catch(err => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Lost password specific user
export const lostPasswordUser = (data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CURRENT_USER, payload: true})
    
    return axios.post(api_url + 'users/password/lost', data)
        .then(res => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            return true
        })
        .catch(err => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
            return false
        })
}

// Populate user from data
export const populateUserFromCompanyData = (company) => dispatch => {
    const address1 = company.l4_normalisee ? company.l4_normalisee : ''
    const address2 = (company.l3_normalisee ? (company.l3_normalisee + ' ') : '') + (company.l2_normalisee ? company.l2_normalisee : '')
    
    const user = {
        company: (company.l1_declaree != '') ? company.l1_declaree : '',
        gender: (company.civilite != '') ? company.civilite : '1',
        siren: company.siren,
        firstname: (company.prenom) ? company.prenom : '',
        lastname: (company.nom) ? company.nom : '',
        email: (company.adr_mail) ? company.adr_mail : '',
        address: address1 + ' ' + address2,
        postalCode: company.codpos,
        city: company.libcom
    }
    dispatch({type: POPULATE_CURRENT_USER, payload: user})
}