import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { loginUser } from '../../../actions/authActions'
import { BarLoader } from 'react-spinners';
import queryString from 'query-string'

class Login extends Component {

  constructor(props) {
    super(props)
    this.state={
      login : '',
      password : '',
      errors: {}
    }

    this.login = this.login.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.isAuthenticatedProcess = this.isAuthenticatedProcess.bind(this)
  }

  componentDidMount() {
    this.isAuthenticatedProcess(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.isAuthenticatedProcess(nextProps)
  }

  isAuthenticatedProcess(props)
  {
    if (props.auth.isAuthenticated) {
      const search = queryString.parse(this.props.location.search)
      let path = search.url_redirect || "/"
      console.log(search)
      console.log(path)
      this.props.history.push(path)
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })
  }

  login() {
    const userData = {
      login: this.state.login,
      password: this.state.password
    }

    this.props.loginUser(userData)
  }

  render() {
    const {errors} = this.props
    const {auth} = this.props
    return (
      <div className="animated fadeIn">
        <h1>Connexion</h1>
        <form className="m-auto" style={{width: 300}}>
          <div className="form-group">
            <small className="form-text text-muted">Entrez votre identifiant</small>
            <input type="login" name="login" className="form-control" aria-describedby="loginHelp" placeholder="Identifiant" 
                  onChange={this.changeInput}
                  value={this.state.login} 
            />
            <small className="form-text text-danger">{errors.login}</small>
          </div>
          <div className="form-group mb-5">
            <small className="form-text text-muted">Entrez votre mot de passe</small>
            <input type="password" name="password" className="form-control" placeholder="Mot de passe" 
                  onChange={this.changeInput}
                  value={this.state.password}   
            />
            <small className="form-text text-danger">{errors.password}</small>
          </div>
          <div className="text-center">
            {
              auth.isLoading ? (
                <BarLoader
                  widthUnit={"%"}
                  width={100}
                  color={'#bb3234'}
                />) : (
                  <div>
                    <button onClick={this.login} className="btn btn-lqo btn-lqo-primary mb-3">ME CONNECTER</button><br/>
                    <Link to="/mot-de-passe-perdu">J'ai perdu mon mot de passe</Link>
                  </div>
              )
            }
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { loginUser })(withRouter(Login));